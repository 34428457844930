<template>
  <div class="comment__items owner-custom-padding">
    <div v-for="item in ownerHistory" :key="item.id" class="comment__item">
      <div class="comment__item-r">
        <div class="comment__item-in">
          <div class="comment__item-top">
            <div class="comment__item-name">
              <span class="comment__item-name_status">
                {{ ownText(item) }}
                <a :href="ownerLink(item)" @click.prevent="goToUserPage(item)">
                  {{ ownerFullName(item) }}
                </a>
              </span>
            </div>

            <div class="comment__item-date">
              <span class="comment__item-date_date">
                {{ getChangeDate(item) }}
              </span>
              <span class="comment__item-date_time">
                {{ getChangeTime(item) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Owners",
  computed: {
    ...mapGetters({
      bike: "bikes/bike",
      ownerHistory: "bikes/owners"
    })
  },
  mounted() {
    if (this.bike) {
      this.$store.dispatch("bikes/loadOwners", { bikeID: this.bike.id });
    }
  },
  methods: {
    ownText(ownerHistory) {
      return ownerHistory["is_buyer"] ? "Purchased by" : "Transfer to";
    },
    getChangeDate(ownerHistory) {
      return this.$moment.unix(ownerHistory["owned_at"]).format("DD.MM.YYYY");
    },
    getChangeTime(ownerHistory) {
      return this.$moment.unix(ownerHistory["owned_at"]).format("H:mm");
    },
    ownerFullName(ownerHistory) {
      return ownerHistory.owner.name;
    },
    ownerLink(ownerHistory) {
      return `/customers/${ownerHistory.owner.id}`;
    },
    goToUserPage(ownerHistory) {
      this.$router.push(`/customers/${ownerHistory.owner.id}`).catch(() => {});
    }
  }
};
</script>
