<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Create Customer</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Personal data</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="createRequestData.f_name"
                      autofocus
                      type="text"
                    />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Surname:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.l_name" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">Gender:</div>
                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentGender"
                      :options="getGenders"
                      label="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Email:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="createRequestData.email" type="email" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Mobile:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="updateRequestData.mobile_phone"
                      type="text"
                    />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  Date of birth:
                </div>
                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <date-picker
                      v-model="birthdate"
                      :format="'DD.MM.YYYY'"
                      value-type="date"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">Account Type:</div>
                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentUserType"
                      :options="userTypes"
                      autocomplete="off"
                      label="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Password:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="createRequestData.password"
                      autocomplete="off"
                      type="password"
                    />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Repeat password:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="createRequestData.password_confirmation"
                      type="password"
                    />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Biometric data</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Height</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.height" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Weight</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.weight" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Invoice address</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>ZIP:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.zip" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Country:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="updateRequestData.country"
                      :allow-empty="true"
                      :internal-search="true"
                      :label="'title'"
                      :limit="5"
                      :options="countries"
                      :placeholder="'Select country'"
                      :showNoResults="false"
                      :track-by="'id'"
                    />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Citу:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="updateRequestData.city"
                      :allow-empty="true"
                      :internal-search="false"
                      :label="'title'"
                      :limit="5"
                      :loading="isLoadingCities"
                      :noOptions="'Start typing'"
                      :options="cities"
                      :placeholder="manualCity || 'Select city'"
                      :preserveSearch="true"
                      :showNoResults="false"
                      :track-by="'id'"
                      @search-change="changeCity"
                    />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Street:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.streetNo" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Flat:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="updateRequestData.flat" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Subscribes</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>News:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-1"
                      v-model="updateRequestData.news"
                      type="checkbox"
                    />
                    <label for="checkbox-1"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Promo:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-2"
                      v-model="updateRequestData.promo"
                      type="checkbox"
                    />
                    <label for="checkbox-2"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Memes:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-3"
                      v-model="updateRequestData.memes"
                      type="checkbox"
                    />
                    <label for="checkbox-3"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Cookies</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Perfomance:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-11"
                      v-model="updateRequestData.cookies.performance"
                      type="checkbox"
                    />
                    <label for="checkbox-11"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Social media &amp; advertising:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-12"
                      v-model="updateRequestData.cookies.social_advertising"
                      type="checkbox"
                    />
                    <label for="checkbox-12"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Save user info</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="goBack()">
              <span><i>Cancel</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import { Multiselect } from "vue-multiselect";
import vSelect from "vue-select";
import _ from "lodash";

function initialState() {
  return {
    currentUserType: {
      code: 3,
      text: "User"
    },
    birthdate: "",
    currentGender: {
      code: "male",
      text: "Male"
    },
    createRequestData: {
      email: "",
      f_name: "",
      password: "",
      password_confirmation: "",
      user_type_id: "",
      removed: false
    },
    updateRequestData: {
      l_name: "",
      gender: "",
      mobile_phone: "",
      birth_date: "",
      height: "",
      weight: "",
      news: false,
      promo: false,
      memes: false,
      city: null,
      country: null,
      streetNo: "",
      flat: "",
      zip: "",
      cookies: {
        performance: false,
        facebook: false,
        social_advertising: false
      }
    },
    cities: [],
    manualCity: null,
    isLoadingCities: false,
    countries: []
  };
}

export default {
  name: "CRMManageCustomers",
  components: {
    CRMLayout,
    DatePicker,
    Multiselect,
    vSelect
  },
  data() {
    return initialState();
  },
  mounted() {
    this.loadUserTypes();
  },
  created() {
    this.loadCountries();
  },
  methods: {
    changeCity(searchQuery) {
      let ctx = this;
      if (searchQuery) {
        ctx.isLoadingCities = true;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            ctx.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            ctx.isLoadingCities = false;
          })
          .catch(() => {
            ctx.isLoadingCities = false;
          });
      }

      if (!(!this.updateRequestData.city && !searchQuery)) {
        this.manualCity = searchQuery;
      }
    },
    loadCountries() {
      let ctx = this;
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          ctx.countries = response.data.map(country => {
            return {
              id: country.id,
              title: country.country_name
            };
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async save() {
      let self = this;
      try {
        let id;
        if (!localStorage.getItem("newManagerId")) {
          let updateData = JSON.parse(JSON.stringify(self.updateRequestData));

          if (updateData.city) {
            updateData.city_id = updateData.city.id;
          } else {
            updateData.city_name = self.manualCity;
          }

          if (updateData.country) {
            updateData.country_id = updateData.country.id;
          }

          delete updateData.city;
          delete updateData.country;

          updateData.news = updateData.news ? "1" : "0";
          updateData.promo = updateData.promo ? "1" : "0";
          updateData.memes = updateData.memes ? "1" : "0";
          updateData.user_type_id = self.currentUserType.code;
          updateData.gender = self.currentGender.code;
          updateData.birth_date = self.birthdate;

          let response = await this.$store.dispatch("create_customer_request", {
            ...this.createRequestData,
            ...updateData
          });
          localStorage.setItem("newManagerId", response.data.data.id);
          id = response.data.data.id;
        } else {
          id = localStorage.getItem("newManagerId");
        }

        let updateData = JSON.parse(JSON.stringify(this.updateRequestData));

        // eslint-disable-next-line
        if (updateData.city && updateData.city.hasOwnProperty("id")) {
          updateData.city_id = updateData.city.id;
        } else {
          updateData.town_name = self.manualCity;
        }

        // eslint-disable-next-line
        if (updateData.country && updateData.country.hasOwnProperty("id")) {
          updateData.country_id = updateData.country.id;
        }

        delete updateData.city;
        delete updateData.country;

        updateData.news = updateData.news ? "1" : "0";
        updateData.promo = updateData.promo ? "1" : "0";
        updateData.memes = updateData.memes ? "1" : "0";

        updateData.user_type_id = self.currentUserType.code;
        updateData.gender = self.currentGender.code;
        updateData.birth_date = self.birthdate;

        await this.$store.dispatch("update_customer_request", {
          data: { ...this["createRequestData"], ...updateData },
          id: id
        });
        localStorage.removeItem("newManagerId");
        self.$root.$emit("modalShow", {
          text: "New user successfully created",
          type: "info"
        });
        self.$router.push("/customers/" + id);
      } catch (e) {
        self.$root.$emit("modalShow", { text: e, type: "error" });
      }
    },
    loadUserTypes() {
      this.$store
        .dispatch("get_user_types")
        .then(() => {
          let userType = this.userTypes.find(item => {
            return item.text === "User";
          });

          if (userType) {
            this.currentUserType = userType;
          }
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    goBack() {
      window.history.length
        ? this.$router.go(-1)
        : this.$router.push({ name: "CRMManageCustomer" }).catch(() => {});
    }
  },
  computed: {
    userTypes() {
      if (
        this.$store.getters.userTypes &&
        this.$store.getters.userBusinessTypes
      ) {
        let types = _.filter(this.$store.getters.userTypes, {
          business_type: _.find(this.$store.getters.userBusinessTypes, {
            name: "b2c"
          }).id
        });
        return types.map(type => {
          return {
            code: type.id,
            text: type.type[0].toUpperCase() + type.type.slice(1)
          };
        });
      }
      return [];
    },
    getGenders() {
      return [
        {
          code: "male",
          text: "Male"
        },
        {
          code: "female",
          text: "Female"
        },
        {
          code: "free",
          text: "Gender Free"
        }
      ];
    }
  }
};
</script>
