<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMPageCreate' }"
        active-class="is-active"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add Page
      </router-link>
    </template>

    <template v-slot:header-title>Pages list</template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Pages</h5>
          </div>
          <div class="scroll">
            <table v-if="hasPages" class="table__typical table__customers">
              <tbody>
                <tr>
                  <th class="pointer" @click="sortRows('id')">
                    <span>ID</span>
                    <span :class="arrowClass('id')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('title')">
                    <span>Title</span>
                    <span :class="arrowClass('title')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('is_active ')">
                    <span>Is Active</span>
                    <span :class="arrowClass('is_active ')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('created_at')">
                    <span>Created</span>
                    <span :class="arrowClass('created_at')"></span>
                  </th>
                </tr>

                <tr
                  v-for="page in filteredPages"
                  :key="page.id"
                  @click="$router.push({ path: `/pages/${page.id}` })"
                >
                  <td>
                    <a :href="`/pages/${page.id}`">{{ page.id }}</a>
                  </td>
                  <td>
                    <a :href="`/pages/${page.id}`">{{ page.title }}</a>
                  </td>
                  <td>
                    <a :href="`/pages/${page.id}`">{{ page.is_active }}</a>
                  </td>
                  <td>
                    <a :href="`/pages/${page.id}`">{{ page.created_at }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          >
          </paginator>
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By name</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <input
                  v-model="byTitleFilter"
                  autocomplete="off"
                  placeholder="Start typing"
                  title="Title Filter"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes1"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes1">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no1"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Paginator from "./partials/Paginator";
// import $ from "jquery";

export default {
  name: "CRMPageList",
  components: { layout: CRMLayout, DatePicker, Paginator },
  data() {
    return {
      pages: [],
      ordering: true,
      descendingOrder: true,
      updateList: true,
      order_by: "id",
      previous_order_field: null,
      createdAtFilter: null,
      lastLoginAtFilter: null,
      archivedFilter: null,
      reseting: false,
      currentPage: 1,
      lastPage: 1,
      total: 0,
      perPage: 0,
      byTitleFilter: null
    };
  },
  mounted() {
    this.loadPages();
  },
  methods: {
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadPages();
    },
    async loadPages() {
      try {
        let options = {
          parameters: []
        };

        options.parameters.push(`page=${this.currentPage}`);

        if (this.order_by) {
          options.parameters.push(`order=${this.order_by}`);
          options.parameters.push(
            `order_type=${this.ordering ? "desc" : "asc"}`
          );
        }

        if (this.createdAtFilter) {
          console.log(this.createdAtFilter);
          options.parameters.push(`created_at=${this.createdAtFilter}`);
        }

        if (!!this.byTitleFilter && this.byTitleFilter.length > 0) {
          options.parameters.push(`title=${this.byTitleFilter}`);
        }

        if (this.archivedFilter) {
          options.parameters.push(`archived=true`);
        }

        let response = await this.$store.dispatch("get_pages", options);

        if (this.updateList) {
          this.pages = response["data"].data;
          this.updateList = true;
          this.currentPage = response["data"]["current_page"];
          this.total = response["data"].total;
          this.perPage = response["data"]["per_page"];

          if (this.pages.length < 6) {
            // noinspection JSCheckFunctionSignatures
            window.$("body, html").animate({ scrollTop: 0 }, 500, "swing");
          }
        } else {
          this.pages = [...this.pages, ...response["data"].data];
        }
      } catch (e) {
        this.$root.$emit("modalShow", { text: e, type: "error" });
      }
    },
    resetFilters() {
      this.updateList = true;
      this.createdAtFilter = null;
      this.archivedFilter = null;
      this.ordering = true;
      this.order_by = null;
      this.byTitleFilter = "";
      this.reseting = true;
      this.currentPage = 1;
      this.lastPage = 1;
      this.loadPages();
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadPages();
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.currentPage = 1;
      this.lastPage = 1;
      this.loadPages();
    },
    sendPushModal() {
      if (this.total === 0) {
        this.$root.$emit("modalShow", {
          type: "error",
          text: "You have not selected any users"
        });
      } else {
        this.$root.$emit("modalShow", {
          type: "push",
          options: {
            total: this.total,
            createdAt: this.createdAtFilter,
            archived: this.archivedFilter,
            byTitle: this.byTitleFilter
          }
        });
      }
    }
  },
  computed: {
    showPaginator() {
      return this.total > this.perPage;
    },
    hasPages() {
      return this.pages.length > 0;
    },
    filteredPages() {
      if (!this.byTitleFilter) {
        return this.pages;
      }
      return this.pages.filter(item => {
        return (
          item.title.toLowerCase().indexOf(this.byTitleFilter.toLowerCase()) !==
          -1
        );
      });
    }
  },
  watch: {
    createdAtFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadPages();
      }
    }
  }
};
</script>

<style>
.table__customers tr {
  cursor: pointer;
}
</style>
