<template>
  <header class="header">
    <div class="header__l">
      <div class="header__l-in">
        <router-link :to="{ name: 'CRMCustomerList' }" class="header__logo">
          <svg
            height="27px"
            version="1.1"
            viewBox="0 0 31 27"
            width="31px"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              id="Admin"
              stroke="none"
              stroke-width="1"
            >
              <g
                id="Products-dropdown"
                transform="translate(-35.000000, -12.000000)"
              >
                <rect height="900" width="1440" x="0" y="0"></rect>
                <g
                  fill="#000000"
                  id="Logo"
                  transform="translate(35.000000, 12.000000)"
                >
                  <path
                    d="M16.1755557,0.375215617 C16.1078379,0.256533189 16.0072998,0.158620185 15.8855739,0.092400785 C15.5242738,-0.103694954 15.0679748,0.022539992 14.8664832,0.374406419 L0.0677178121,26.2250577 C0.0232650152,26.3026059 0,26.3899993 0,26.4788762 C0,26.7666811 0.239574263,27 0.535095349,27 L6.56973563,27 C7.23472731,27 7.47250131,26.9635861 7.73063219,26.8836103 C7.98848611,26.8036345 8.21241188,26.6800969 8.41528835,26.5057147 C8.6183033,26.3311975 8.77243403,26.1511509 9.1140697,25.5953665 L12.3456911,20.3388138 C12.3681252,20.3023999 12.3798962,20.2608611 12.3798962,20.2183782 C12.3798962,20.0889064 12.272157,19.9838455 12.1390756,19.9838455 L10.6894436,19.9838455 C10.6445753,19.9838455 10.600538,19.9715727 10.5623169,19.9485105 C10.4493154,19.8802681 10.4146948,19.7355565 10.4849053,19.6256403 L15.3173813,12.0526225 C15.3367688,12.0221427 15.3633574,11.9963832 15.3945159,11.977367 C15.5075174,11.9089898 15.6559704,11.9425715 15.7263194,12.0524876 L20.5672428,19.6253706 C20.5910617,19.6625937 20.6036636,19.7056161 20.6036636,19.7493128 C20.6036636,19.8789194 20.495786,19.9838455 20.3627045,19.9838455 L18.7005023,19.9838455 C18.6567419,19.9838455 18.6135355,19.9955789 18.5760068,20.017697 C18.4623129,20.0847256 18.4257535,20.2288977 18.4945792,20.339623 L21.762206,25.6045374 C22.105088,26.1572199 22.2593572,26.3360527 22.4620952,26.5093561 C22.6646947,26.6826594 22.8880665,26.8052529 23.1452281,26.8846892 C23.402528,26.9638558 23.6393327,27 24.3023856,27 L30.4647065,27 C30.5556893,27 30.6451488,26.9773424 30.7247761,26.9343201 C30.9830455,26.7944636 31.0761055,26.477123 30.9324995,26.2255972 L16.1755557,0.375215617 Z"
                  ></path>
                </g>
              </g>
            </g>
          </svg> </router-link
        ><!-- .header__logo -->
      </div>
      <!-- .header__l-in -->
    </div>
    <!-- .header__l -->

    <div class="header__r">
      <div class="header__r-in">
        <div class="header__r-l">
          <a @click="goBack()" class="btn btn-back" v-if="$router.currentRoute.name !== 'CRMDashBoard'">Back</a>
          <slot> </slot>
        </div>
        <!-- .header__r-l -->

        <div class="header__r-c">
          <span class="header__title">
            <slot name="title"></slot>
          </span>
        </div>
        <!-- .header__r-c -->

        <div class="header__r-r">
          <div class="header__search">
            <div class="inp-group inp-group__search">
              <input type="search" />
            </div>
            <!-- .inp-group inp-group__search -->
          </div>
          <!-- .header__search -->

          <div class="header__profile">
            <span class="header__profile-name">{{ fullName }}</span>

            <button @click="logout" class="btn btn-exit"></button>
          </div>
          <!-- .header__profile -->
        </div>
        <!-- .header__r-r -->
      </div>
      <!-- .header__r-in -->
    </div>
    <!-- .header__r -->
  </header>
  <!-- .header -->
</template>

<script>
export default {
  name: "HeaderMenu",
  data() {
    return {
      currentUser: {
        l_name: "",
        f_name: ""
      }
    };
  },
  computed: {
    fullName() {
      let currentUser = this.$store.getters.user;
      return currentUser.f_name + " " + (currentUser.l_name || "");
    }
  },
  methods: {
    logout: function() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login").catch(() => {});
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    goBack() {
      window.history.length
        ? this.$router.go(-1)
        : this.$router.push({ name: "CRMDashBoard" }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.header__r-c {
  margin: 0 0.5rem;
}
.header__title {
  font-size: 14px;
  color: #2b2b2b;
  white-space: nowrap;
}
.header__r-l {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.add-models-btn {
  padding: 8px 14px !important;
  margin-right: 20px;
  display: inline-block;
  line-height: 14px;
  font-weight: bold;
  font-size: 14px;
  color: #454545;
  margin-bottom: 1px;
  margin-top: 1px;
}

.btn-back {
  margin-right: 20px;
}

@media screen and (max-width: 1300px) {
  .add-models-btn {
    width: auto;
    min-width: auto;
    margin-right: 5px;
    font-size: 12px;
  }
  .header__profile-name {
    margin-right: 8px;
    margin-left: 24px;
  }
}
@media screen and (max-width: 991px) {
  .header__l {
    position: absolute;
    left: 0;
    top: 14px;
  }
  .header__r {
    width: 100%;
    padding-left: 95px;
  }
  .header__r-l {
    width: 100%;
    order: 3;
    margin-top: 1rem;
  }
  .header__r-in {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .header {
    margin: 0 0 12px;
    padding: 12px 18px;
  }
  .header__l {
    box-sizing: border-box;
    left: 18px;
    width: 31px;
    padding: 0;
  }
  .header__r {
    width: 100%;
    padding: 0 0 0 64px;
  }
  .header__r-r {
    width: 100%;
    order: 2;
    flex-wrap: wrap;
    margin-top: 7px;
  }
  .inp-group__search,
  .header__search {
    width: 100%;
    min-width: 100%;
  }
  .header__search {
    order: 2;
  }
  .header__profile {
    margin: 0 0 1rem auto;
  }
  .header__r-c {
    margin: 0;
    position: absolute;
    top: 19px;
  }
  .header__r-l {
    min-width: calc(100% + 64px);
    margin-left: -64px;
    padding-left: 64px;
    position: relative;
    box-sizing: border-box;
  }
  .header .btn-back:not(:last-child) {
    padding-left: 16px;
    position: absolute;
    left: 0;
    top: 9px;
  }
  .header .btn-back:last-child {
    position: relative;
    left: -66px;
    margin-top: -64px;
  }
  .add-models-btn {
    padding: 0.5rem !important;
  }
  .header__profile-name {
    font-size: 12px;
  }
  .header__r-c {
    max-width: calc(100% - 216px);
    width: 100%;
  }
  .header__title {
    font-size: 12px;
    white-space: inherit;
    line-height: 1;
    display: inline-block;
  }
}
</style>
