<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Create B2B Customer</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Personal data</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Company Name:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="customer.f_name" autofocus type="text" />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Shop Name:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="customer.l_name" autofocus type="text" />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Account type:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentUserType"
                      :options="userTypes"
                      autocomplete="off"
                      label="text"
                      placeholder="Select user type"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Website:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.b2b_data.website"
                      autofocus
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Password:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.password"
                      autocomplete="off"
                      type="password"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Repeat password:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.password_confirmation"
                      type="password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Full address</h5>
            </div>

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Address:</span>
                </div>
                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.invoice_address.streetNo"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Postal Code:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.invoice_address.zip"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Country:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="customer.invoice_address.country"
                      :allow-empty="true"
                      :internal-search="true"
                      :label="'title'"
                      :limit="5"
                      :options="countries"
                      :placeholder="'Select country'"
                      :showNoResults="false"
                      :track-by="'id'"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Citу:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="customer.invoice_address.city"
                      :allow-empty="true"
                      :internal-search="false"
                      :label="'title'"
                      :limit="5"
                      :loading="isLoadingInvoiceAddressCities"
                      :noOptions="'Start typing'"
                      :options="cities"
                      :placeholder="manualInvoiceCity || 'Select city'"
                      :preserveSearch="true"
                      :showNoResults="false"
                      :track-by="'id'"
                      @search-change="changeInvoiceCity"
                    />
                  </div>
                </div>
              </div>

              <hr class="form_hr" />

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Delivery address:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="sameAddress"
                      :allow-empty="false"
                      :label="'title'"
                      :options="addressesOptions"
                      :placeholder="'Select'"
                      :track-by="'value'"
                    />
                  </div>
                </div>
              </div>

              <div v-if="!sameAddress.value">
                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Address:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="customer.delivery_address.streetNo"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Postal Code:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="customer.delivery_address.zip"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Country:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <multiselect
                        v-model="customer.delivery_address.country"
                        :allow-empty="true"
                        :internal-search="true"
                        :label="'title'"
                        :limit="5"
                        :options="countries"
                        :placeholder="'Select country'"
                        :showNoResults="false"
                        :track-by="'id'"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Citу:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <multiselect
                        v-model="customer.delivery_address.city"
                        :allow-empty="true"
                        :internal-search="false"
                        :label="'title'"
                        :limit="5"
                        :loading="isLoadingDeliveryAddressCities"
                        :noOptions="'Start typing'"
                        :options="cities"
                        :placeholder="manualDeliveryCity || 'Select city'"
                        :preserveSearch="true"
                        :showNoResults="false"
                        :track-by="'id'"
                        @search-change="changeDeliveryCity"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Contacts</h5>
            </div>

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Main contact telephone:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.mobile_phone"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Alternative contact telephone:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.b2b_data.alt_phone"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Main contact email address:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.email"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Alternative contact email address:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="customer.b2b_data.alt_email"
                      title="Height"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <hr class="form_hr" />

              <div :key="index" v-for="(contact, index) in customer.contacts">
                <h3 class="text-center mb-15">
                  <span v-if="index === 0">Main</span
                  ><span v-else>Alternative</span> contact person
                  <button
                    class="btn btn-white btn-bold"
                    @click="removeContact(index)"
                  >
                    Delete
                  </button>
                </h3>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Full Name:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="contact.full_name"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Position:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="contact.position"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Email:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="contact.email"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Phone:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="contact.phone"
                        title="Height"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <hr class="form_hr" />
              </div>

              <div class="text-center">
                <button class="btn btn-white btn-bold" @click="addContact">
                  Add contact
                </button>
              </div>
            </div>
          </div>

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Other</h5>
            </div>

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Payment Terms:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__textarea">
                    <textarea
                      v-model="customer.b2b_data.payment_terms"
                      type="text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Delivery Terms:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__textarea">
                    <textarea
                      v-model="customer.b2b_data.delivery_terms"
                      type="text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Bank Account (IBAN/Swift):</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__textarea">
                    <textarea
                      v-model="customer.b2b_data.bank_account"
                      type="text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Notes:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__textarea">
                    <textarea
                      v-model="customer.b2b_data.notes"
                      type="text"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Save user info</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="goBack()">
              <span><i>Cancel</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import { Multiselect } from "vue-multiselect";
import _ from "lodash";

export default {
  name: "CRMManageCustomersB2b",
  components: {
    CRMLayout,
    vSelect,
    Multiselect
  },
  data() {
    return {
      sameAddress: { value: true, title: "Same as full address" },
      addressesOptions: [
        { value: true, title: "Same as full address" },
        { value: false, title: "Other" }
      ],
      currentUserType: null,
      userTypes: [],
      countries: [],
      cities: [],
      manualInvoiceCity: null,
      manualDeliveryCity: null,
      isLoadingInvoiceAddressCities: false,
      isLoadingDeliveryAddressCities: false,
      customer: {
        email: "",
        f_name: "",
        l_name: "",
        password: "",
        password_confirmation: "",
        user_type_id: null,
        b2b_data: {
          website: "",
          alt_phone: "",
          alt_email: "",
          payment_terms: "",
          delivery_terms: "",
          bank_account: "",
          notes: ""
        },
        invoice_address: {
          city: null,
          country: null,
          flat: null,
          streetNo: "",
          zip: ""
        },
        delivery_address: {
          city: null,
          country: null,
          flat: null,
          streetNo: "",
          zip: ""
        },
        contacts: []
      }
    };
  },
  beforeMount() {
    this.loadUserTypes();
  },
  created() {},
  methods: {
    addContact() {
      this.customer.contacts.push({
        full_name: "",
        position: "",
        email: "",
        phone: ""
      });
    },
    removeContact(contact_index) {
      this.customer.contacts = _.reject(this.customer.contacts, function(o, i) {
        return i === contact_index;
      });
    },
    changeInvoiceCity(searchQuery) {
      if (searchQuery) {
        this.isLoadingInvoiceAddressCities = true;
        this.customer.invoice_address.city = null;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            this.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            this.isLoadingInvoiceAddressCities = false;
          })
          .catch(() => {
            this.isLoadingInvoiceAddressCities = false;
          });
      }

      if (!(!this.customer.invoice_address.city && !searchQuery)) {
        this.manualInvoiceCity = searchQuery;
      }
    },
    changeDeliveryCity(searchQuery) {
      if (searchQuery) {
        this.isLoadingDeliveryAddressCities = true;
        this.customer.delivery_address.city = null;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            this.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            this.isLoadingDeliveryAddressCities = false;
          })
          .catch(() => {
            this.isLoadingDeliveryAddressCities = false;
          });
      }

      if (!(!this.customer.delivery_address.city && !searchQuery)) {
        this.manualDeliveryCity = searchQuery;
      }
    },
    loadUserTypes() {
      let ctx = this;
      this.$store
        .dispatch("get_user_types")
        .then(resp => {
          let b2bType = _.find(this.$store.getters.userBusinessTypes, {
            name: "b2b"
          });
          ctx.userTypes = _.filter(resp.data, {
            business_type: b2bType.id
          }).map(type => {
            return {
              code: type.id,
              text: type.type[0].toUpperCase() + type.type.slice(1)
            };
          });
          this.loadCountries();
          this.setDefaultUserType();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    setDefaultUserType() {
      this.currentUserType = _.first(this.userTypes);
    },
    loadCountries() {
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          this.countries = response.data.map(country => {
            return {
              id: country.id,
              title: country.country_name
            };
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async save() {
      let self = this;
      let createData = self.formData;

      this.$store
        .dispatch("create_customer_b2b_request", createData)
        .then(response => {
          self.$root.$emit("modalShow", {
            text: "New user successfully created",
            type: "info"
          });
          self.$router.push("/customers/" + response.data.data.id);
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    goBack() {
      window.history.length
        ? this.$router.go(-1)
        : this.$router.push({ name: "CRMManageCustomer" }).catch(() => {});
    }
  },
  computed: {
    formData() {
      let data = this.customer;

      data.user_type_id = this.currentUserType.code;

      if (data.invoice_address.city) {
        data.invoice_address.city_id = data.invoice_address.city.id;
      } else {
        data.invoice_address.city_name = this.manualInvoiceCity;
      }
      if (data.invoice_address.country) {
        data.invoice_address.country_id = data.invoice_address.country.id;
      }
      if (data.delivery_address.city) {
        data.delivery_address.city_id = data.delivery_address.city.id;
      } else {
        data.delivery_address.city_name = this.manualDeliveryCity;
      }
      if (data.delivery_address.country) {
        data.delivery_address.country_id = data.delivery_address.country.id;
      }

      if (this.sameAddress.value === true) {
        data.delivery_address = Object.assign({}, data.invoice_address);
      }

      return data;
    }
  }
};
</script>

<style scoped>
.form_hr {
  left: 0;
  max-width: 700px;
}
</style>
