<template>
  <CRMLayout>
    <template v-slot:content>
      <div class="main__c bg-transparent">
        <div class="main__c-in d-flex">
          <div class="sticky-content">
            <draggable v-model="albums" :sort="true" tag="div">
              <media-item-component
                v-for="(album, index) in albums"
                :key="album.id"
                ref="mediaItem"
                :album="album"
                :index="index"
                :isCreatePage="isPageCreate()"
              />
            </draggable>
            <div
              :class="{ indentTop: albums.length, mt0: !albums.length }"
              class="main__content-btns"
            >
              <button class="btn btn-sm btn-fw bg-white bold" @click="addAlbum">
                <span>Add album</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Publication settings</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Published</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__radio-wrapper gray">
                <div class="inp-group__radio ">
                  <input
                    id="on"
                    v-model="active"
                    :value="true"
                    name="active"
                    type="radio"
                  />
                  <label for="on">ON</label>
                </div>
                <div class="inp-group__radio ">
                  <input
                    id="off"
                    v-model="active"
                    :value="false"
                    name="active"
                    type="radio"
                  />
                  <label for="off">OFF</label>
                </div>
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Start activity</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <date-picker
                  v-model="start_activity"
                  :format="'YYYY/MM/DD'"
                  size="small"
                  type="date"
                  valueType="YYYY-MM-DD"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>End activity</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <date-picker
                  v-model="end_activity"
                  :format="'YYYY/MM/DD'"
                  size="small"
                  type="date"
                  valueType="YYYY-MM-DD"
                />
              </div>
            </div>
          </div>
          <div v-if="first_activity" class="publication-text">
            <h5>First publication date</h5>
            <p class="data">{{ first_activity }}</p>
          </div>
          <div class="fix-wrap">
            <div class="btn__wrapper">
              <button
                :class="{
                  'btn-green': !(isSaving || isUploading),
                  'btn-white': isSaving || isUploading
                }"
                class="btn btn-reset btn-fw btn-bold btn-lg loading"
                @click="save"
              >
                {{
                  isSaving ? "SAVING..." : isUploading ? "UPLOADING..." : "SAVE"
                }}
              </button>
            </div>
            <div v-if="!isPageCreate()" class="btn__wrapper">
              <a
                class="btn btn-white btn-reset btn-fw btn-bold btn-lg"
                style="display: block"
                target="_blank"
                @click="confirmDelete"
              >
                {{ deleted_at === null ? "DELETE" : "RESTORE" }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import draggable from "vuedraggable";
import { mapGetters, mapState } from "vuex";
import MediaItemComponent from "./partials/MediaItemComponent";
import DatePicker from "vue2-datepicker";

export default {
  name: "CRMMediaLibraryContent",
  components: {
    CRMLayout,
    draggable,
    DatePicker,
    MediaItemComponent
  },
  computed: {
    ...mapGetters({
      isActiveLang: "languages/isActive"
    }),
    ...mapState({
      languages: store => store.languages.list,
      id: store => store.mediaItem.id,
      deleted_at: store => store.mediaItem.deleted_at,
      first_activity: store => store.mediaItem.first_activity
    }),
    start_activity: {
      get() {
        return this.$store.state.mediaItem.start_activity;
      },
      set(value) {
        this.$store.dispatch("mediaItem/set_start_activity", value);
      }
    },
    end_activity: {
      get() {
        return this.$store.state.mediaItem.end_activity;
      },
      set(value) {
        this.$store.dispatch("mediaItem/set_end_activity", value);
      }
    },
    albums: {
      get() {
        return this.$store.getters["mediaItem/getAlbums"];
      },
      set(items) {
        items.forEach(function(item, index) {
          item.sort_order = index;
        });

        this.$store.dispatch("mediaItem/update_sort_order", items);
      }
    },
    active: {
      get() {
        return this.$store.state.mediaItem.active;
      },
      set(value) {
        this.$store.dispatch("mediaItem/set_active", value);
      }
    }
  },
  data() {
    return {
      isSaving: false,
      isUploading: false
    };
  },
  created() {
    this.loadItem();
  },
  mounted() {
    this.$root.$on("confirmed", data => {
      if (data.options.confirmType === "deleteOrRestore") {
        this.deleteOrRestore();
      }
    });
    let ctx = this;
    this.$root.$on("vdropzone-processing", function() {
      ctx.isUploading = true;
    });
    this.$root.$on("vdropzone-success", function() {
      ctx.isUploading = false;
    });
  },
  methods: {
    loadItem() {
      if (this.isPageCreate()) {
        this.$store.dispatch("mediaItem/clearState");
        this.$store.dispatch("mediaItem/add_new_album");
      } else {
        this.$store.dispatch(
          "mediaItem/get_media_request",
          this.$route.params.id
        );
      }
    },
    isPageCreate() {
      return this.$route.name === "CRMMediaLibraryCreate";
    },
    save() {
      if (this.isSaving || this.isUploading) {
        return;
      }

      this.isSaving = true;

      if (this.isPageCreate()) {
        this.$store
          .dispatch("mediaItem/create_request")
          .then(() =>
            this.$root.$emit("modalShow", {
              text: "Media successfully created",
              type: "info"
            })
          )
          .catch(error =>
            this.$root.$emit("modalShow", { text: error, type: "error" })
          )
          .finally(() => (this.isSaving = false));
      } else {
        this.$store
          .dispatch("mediaItem/save_request")
          .then(() => {
            this.$root.$emit("modalShow", {
              text: "Media successfully saved",
              type: "info"
            });
            this.$router.push("/media/").catch(() => {});
          })
          .catch(error =>
            this.$root.$emit("modalShow", { text: error, type: "error" })
          )
          .finally(() => (this.isSaving = false));
      }
    },
    confirmDelete() {
      this.$root.$emit("modalShow", {
        text: "are you sure?",
        type: "confirm",
        options: {
          confirmType: "deleteOrRestore"
        }
      });
    },
    deleteOrRestore() {
      if (this.deleted_at === null) {
        this.$store
          .dispatch("mediaItem/delete_request")
          .then(() => {
            this.$router.push("/media/").catch(() => {});
            this.$root.$emit("modalShow", {
              text: "Media successfully delete",
              type: "info"
            });
          })
          .catch(err => {
            this.$root.$emit("modalShow", { text: err, type: "error" });
          });
      } else {
        this.$store
          .dispatch("mediaItem/restore_request")
          .then(() => {
            this.$router.push("/media/").catch(() => {});
            this.$root.$emit("modalShow", {
              text: "Media successfully delete",
              type: "info"
            });
          })
          .catch(err => {
            this.$root.$emit("modalShow", { text: err, type: "error" });
          });
      }
    },
    addAlbum() {
      this.$store.dispatch("mediaItem/add_new_album");
    },
    changeLang(language) {
      this.$store.dispatch("languages/change", language);
    }
  }
};
</script>

<style scoped>
body,
html {
  overflow: initial;
  max-width: calc(100% - 5px);
}

.sticky-content {
  padding-top: unset;
}

.main__content-btns.mt0 {
  margin-top: 0 !important;
}
</style>
