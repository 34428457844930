<template>
  <layout>
    <template v-slot:header-title>
      <span v-if="canEdit">Edit customer</span>
      <span v-else>Customer page</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Personal data</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Avatar:</span>
                </div>

                <div class="main__content-item_r">
                  <div
                    v-if="hasAvatar && !canEdit"
                    class="main__content-avatar"
                  >
                    <img v-if="!canEdit" :alt="fullName" :src="avatar" />
                    <button
                      v-if="canEdit"
                      class="main__content-avatar_close"
                    ></button>
                  </div>
                  <dropzone
                    v-if="canEdit"
                    :id="'customer_avatar'"
                    :default-image="avatar"
                    :options="dropzoneSettings"
                    dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 512x512 up to 15MB)"
                    @vdropzone-files-added="saveLocalAvatar"
                    @vdropzone-removed-file="resetLocalAvatar"
                  />
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>ID:</span>
                </div>

                <div class="main__content-item_r">
                  <span>
                    <b>{{ customer.id }}</b>
                  </span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-if="canEdit"
                      v-model="customer.f_name"
                      autofocus
                      title="F name"
                      type="text"
                    />
                    <span v-else>{{ customer.f_name }}</span>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Surname:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-if="canEdit"
                      v-model="customer.l_name"
                      title="L Name"
                      type="text"
                    />
                    <span v-else>{{ customer.l_name }}</span>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Gender:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentGender"
                      :options="getGenders"
                      label="label"
                      placeholder="Select Gender"
                    />
                  </div>
                  <span v-else>{{ formatGender }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Email:</span>
                </div>

                <div class="main__content-item_r flex">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <input
                      v-model="customer.email"
                      title="Email"
                      type="email"
                    />
                  </div>
                  <span v-else>{{ customer.email }}</span>

                  <span v-if="customer.verify_email" class="fl">Confirmed</span>
                  <span v-else class="fl"
                    >Not confirmed
                    <a href="#" @click.prevent="confirmUser">Confirm?</a></span
                  >
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Mobile:</span>
                </div>

                <div class="main__content-item_r">
                  <div
                    v-if="canEdit"
                    id="phone"
                    class="inp-group inp-group__xl"
                  >
                    <input
                      v-model="customer.mobile_phone"
                      title="phone"
                      type="text"
                    />
                  </div>
                  <span v-else>{{ customer.mobile_phone }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Birth date:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <date-picker
                      v-model="customer.birth_date"
                      :format="'YYYY/MM/DD'"
                      type="date"
                      valueType="YYYY-MM-DD HH:mm:ss"
                    />
                  </div>
                  <span v-else>{{ customer.birth_date || "Not set" }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Reg. date:</span>
                </div>

                <div class="main__content-item_r">
                  <span>{{ regDate }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Last log in:</span>
                </div>

                <div class="main__content-item_r">
                  <span>{{ lastLogin }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Account type:</span>
                </div>

                <div class="main__content-item_r">
                  <span v-if="!canEdit">{{ userType }}</span>
                  <div v-else class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentUserType"
                      :options="userTypes"
                      autocomplete="off"
                      label="text"
                      placeholder="Select user type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Biometric data</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <button class="btn btn-info">
                  <span class="btn-info__in">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Temporibus nulla voluptates odit autem id, quo qui dolore
                    minus cupiditate consectetur. Placeat praesentium minima
                    doloribus tenetur reprehenderit sunt esse sequi vel!
                  </span>
                </button>
                <span>Age</span>
              </div>

              <div class="main__content-item_r">
                <span>{{ getAge }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Height</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input v-model="customer.height" title="Height" type="text" />
                </div>
                <span v-else>{{ customer.height }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Weight</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input v-model="customer.weight" title="weight" type="text" />
                </div>
                <span v-else>{{ customer.weight }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="orders.length > 0" class="main__content">
          <div class="main__content-headline">
            <h5>Orders</h5>
          </div>

          <div class="main__content-in">
            <div
              v-for="order in orders"
              :key="order.id"
              class="main__content-item main__content-item_center"
            >
              <div class="main__content-item_l">
                <span>
                  Amount
                  {{ $crConverter(order.total_price / 100, "EUR") }} date:
                  {{ $moment(order.created_at).format("MM/DD/YYYY") }}:
                </span>
              </div>

              <div class="main__content-item_r">
                <span>
                  <router-link :to="`/orders/${order.id}`">
                    order_{{ order.id }}
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Pro forma address</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>ZIP:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="customer.zip" title="ZIP" type="text" />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Country:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.country"
                    :allow-empty="true"
                    :internal-search="true"
                    :label="'title'"
                    :limit="5"
                    :options="countries"
                    :placeholder="'Select country'"
                    :showNoResults="false"
                    :track-by="'id'"
                  />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Citу:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.city"
                    :allow-empty="true"
                    :internal-search="false"
                    :label="'title'"
                    :limit="5"
                    :loading="isLoadingCities"
                    :noOptions="'Start typing'"
                    :options="cities"
                    :placeholder="manualCity || 'Select city'"
                    :preserveSearch="true"
                    :showNoResults="false"
                    :track-by="'id'"
                    @search-change="changeCity"
                  />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Street:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input
                    v-model="customer.streetNo"
                    title="Street NO"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Flat:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="customer.flat" title="Flat" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Subscribes</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>News:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__checkbox">
                  <input
                    id="checkbox-1"
                    v-model="customer.news"
                    :disabled="!canEdit"
                    type="checkbox"
                  />
                  <label for="checkbox-1"></label>
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Promo:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__checkbox">
                  <input
                    id="checkbox-2"
                    v-model="customer.promo"
                    :disabled="!canEdit"
                    type="checkbox"
                  />
                  <label for="checkbox-2"></label>
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Memes:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__checkbox">
                  <input
                    id="checkbox-3"
                    v-model="customer.memes"
                    :disabled="!canEdit"
                    type="checkbox"
                  />
                  <label for="checkbox-3"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Cookies</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Perfomance:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__checkbox">
                  <input
                    id="checkbox-11"
                    v-model="customer.cookies.performance"
                    :disabled="!canEdit"
                    type="checkbox"
                  />
                  <label for="checkbox-11"></label>
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Social media &amp; advertising:</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__checkbox">
                  <input
                    id="checkbox-12"
                    v-model="customer.cookies.social_advertising"
                    :disabled="!canEdit"
                    type="checkbox"
                  />
                  <label for="checkbox-12"></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content-btns">
          <button v-if="canEdit" class="btn btn-big btn-fw" @click="update">
            <span><i>Save user info</i></span>
          </button>
          <button
            v-if="canEdit"
            class="btn btn-big btn-fw"
            @click="deleteCustomer()"
          >
            <span><i>Delete</i></span>
          </button>
          <button class="btn btn-big btn-fw" @click="goBack()">
            <span><i>Cancel</i></span>
          </button>
        </div>

        <div v-if="false" class="main__content">
          <div class="main__content-headline">
            <h5>Statistics - bike1</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Connected apps:</span>
              </div>

              <div class="main__content-item_r">
                <svg
                  height="20px"
                  viewBox="0 0 16 20"
                  width="16px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Admin"
                    fill="none"
                    fill-rule="evenodd"
                    stroke="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  >
                    <g
                      id="Customer"
                      stroke="#323232"
                      stroke-width="1.05"
                      transform="translate(-619.000000, -1694.000000)"
                    >
                      <g
                        id="Statistics"
                        transform="translate(110.000000, 1660.000000)"
                      >
                        <g
                          id="apple"
                          transform="translate(510.000000, 34.000000)"
                        >
                          <path
                            id="Stroke-5364"
                            d="M7.10897652,6.44054454 C6.42991517,6.44054454 5.37198727,5.62962963 4.2726532,5.62962963 C2.8120502,5.62962963 1.47359544,6.54668524 0.720002965,7.89042644 C-0.795463217,10.5885229 0.32974972,14.574106 1.80795034,16.7669728 C2.53359364,17.8358096 3.38863126,19.0373223 4.52419574,18.9991116 C5.61214312,18.9513483 6.02102914,18.2741707 7.34188628,18.2741707 C8.65239187,18.2741707 9.02504749,18.9991116 10.1782096,18.9704536 C11.3500045,18.9513483 12.0932454,17.8835729 12.8095723,16.8062449 C13.6376959,15.5665215 13.9813672,14.3650089 14,14.2981402 C13.9720508,14.2885876 11.721625,13.4023128 11.6936758,10.7318129 C11.675043,8.49967403 13.4700009,7.43296004 13.5538484,7.38519673 C12.5300806,5.84934087 10.9597512,5.67845435 10.4100842,5.6402437 C8.97846554,5.52561175 7.77768632,6.44054454 7.10897652,6.44054454 Z"
                          ></path>
                          <path
                            id="Stroke-5365"
                            d="M7.7,3.63540322 C8.59285393,3.72477359 9.3705618,3.43414396 9.8,3.05203285"
                          ></path>
                          <path
                            id="Stroke-5366"
                            d="M10.0142943,2.91111111 C10.6470943,2.42379253 11.0222943,1.62274566 10.6176943,0.8"
                          ></path>
                          <path
                            id="Stroke-5367"
                            d="M10.4,0.668286426 C9.50754717,0.578916056 8.72924528,0.870249389 8.3,1.2516568"
                          ></path>
                          <path
                            id="Stroke-5368"
                            d="M8.08514574,1.40740741 C7.45234574,1.89472599 7.07854574,2.69667698 7.48314574,3.51851852"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Runtime, total, hours:</span>
              </div>

              <div class="main__content-item_r">
                <span>330</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Distance, total, km:</span>
              </div>

              <div class="main__content-item_r">
                <span>2 950</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Bike errors:</span>
              </div>

              <div class="main__content-item_r">
                <span><a href="#">Download</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import Dropzone from "./forms/inputs/Dropzone";
import { STORAGE_ENDPOINT } from "@/utils/api";
import { Multiselect } from "vue-multiselect";
import _ from "lodash";

export default {
  name: "CRMCustomerPage",
  components: {
    layout: CRMLayout,
    vSelect,
    DatePicker,
    Dropzone,
    Multiselect
  },
  data() {
    return {
      customer: {
        id: "",
        avatar_path: "",
        f_name: "",
        l_name: "",
        gender: "male",
        email: "",
        mobile_phone: "",
        birth_date: "",
        user_type_id: null,
        verify_email: null,
        height: 0,
        weight: 0,
        news: false,
        promo: false,
        memes: false,
        city: null,
        country: null,
        flat: null,
        streetNo: "",
        zip: "",
        last_login: "",
        biometric_data: null,
        cookies: {
          performance: false,
          facebook: false,
          social_advertising: false
        },
        last_login_timestamp: "Not login yet."
      },
      currentGender: null,
      currentUserType: null,
      defaultUserType: {
        code: 3,
        text: "User"
      },
      can_edit: true,
      userTypes: [],
      fullUserTypes: [],
      local_avatar: null,
      cities: [],
      countries: [],
      manualCity: null,
      isLoadingCities: false,
      orders: [],
      user: JSON.parse(localStorage.getItem("user"))
    };
  },
  mounted() {
    this.$root.$on("confirmed", data => {
      if (data.options.confirmType === "set_b2b_type") {
        this.setB2bRole();
      }
    });
    this.$root.$on("cancelConfirmation", data => {
      if (data.options.confirmType === "set_b2b_type") {
        this.currentUserType = this.defaultUserType;
      }
    });
  },
  created() {
    this.loadUserTypes();
  },
  methods: {
    setB2bRole() {
      this.$store
        .dispatch("update_customer_type", {
          data: { type_id: this.currentUserType.code },
          id: this.$route.params.id
        })
        .then(() => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: "Successfully updated"
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    confirmUser() {
      this.$dialog
        .confirm("Are you sure you want to verify email of this customer?")
        .then(() => {
          this.verifyEmail();
        })
        .catch(() => {});
    },
    verifyEmail() {
      this.$store
        .dispatch("user/verify", this.customer.id)
        .then(() => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: "User verified successfully"
          });
          this.loadUserTypes();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    setCanEdit() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 2) {
          if (permission.edit === 1) {
            this.can_edit = true;
          }
          this.can_edit = false;
        }
      }
    },
    changeCity(searchQuery) {
      if (searchQuery) {
        this.isLoadingCities = true;
        this.customer.city = null;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            this.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            this.isLoadingCities = false;
          })
          .catch(() => {
            this.isLoadingCities = false;
          });
      }

      if (!(!this.customer.city && !searchQuery)) {
        this.manualCity = searchQuery;
      }
    },
    loadCountries() {
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          this.countries = response.data.map(country => {
            return {
              id: country.id,
              title: country.country_name
            };
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    resetLocalAvatar() {
      this.local_avatar = null;
    },
    saveLocalAvatar(file) {
      this.local_avatar = file[0];
    },
    loadCustomerData() {
      let self = this;
      this.$store
        .dispatch("get_customer", this.$route.params.id)
        .then(response => {
          for (let key of Object.keys(response.data)) {
            // eslint-disable-next-line
            if (this.customer.hasOwnProperty(key)) {
              this.customer[key] = response.data[key];
            }
          }

          if (response.data.invoice_address) {
            this.customer.zip = response.data.invoice_address.zip;
            this.customer.flat = response.data.invoice_address.flat;
            this.customer.streetNo = response.data.invoice_address.streetNo;
            if (response.data.invoice_address.city) {
              this.customer.city = {
                id: response.data.invoice_address.city.id,
                title: response.data.invoice_address.city.town_name
              };
            }
            if (response.data.invoice_address.country) {
              this.customer.country = {
                id: response.data.invoice_address.country.id,
                title: response.data.invoice_address.country.country_name
              };
            }
          }
          self.customer.verify_email = response.data["email_verified_at"]
            ? response.data["email_verified_at"]
            : 0;
          self.customer.height = response.data.biometric_data
            ? response.data.biometric_data.height
            : 0;
          self.customer.weight = response.data.biometric_data
            ? response.data.biometric_data.weight
            : 0;
          self.customer.news = response.data.subscribe
            ? response.data.subscribe.news
            : false;
          self.customer.promo = response.data.subscribe
            ? response.data.subscribe.promo
            : false;
          self.customer.memes = response.data.subscribe
            ? response.data.subscribe.memes
            : false;
          response.data["subscriptions"].forEach(function(item) {
            if (item.code === "promo") {
              self.customer.promo = 1;
            }
            if (item.code === "news") {
              self.customer.news = 1;
            }
            if (item.code === "memes") {
              self.customer.memes = 1;
            }
          });

          if (self.customer.gender) {
            self.$emit("setSelectedOptions", {
              text:
                self.customer.gender[0].toUpperCase() +
                self.customer.gender.slice(1),
              value: self.customer.gender
            });
          }
          if (self.customer.user_type_id) {
            for (let userType of self.userTypes) {
              if (userType.code === self.customer.user_type_id) {
                self.currentUserType = userType;
                self.defaultUserType = userType;
              }
            }
          }

          this.loadCustomerOrders();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    loadCustomerOrders() {
      this.$store
        .dispatch("get_customer_orders_request", this.customer.id)
        .then(response => {
          this.orders = response.data;
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    loadUserTypes() {
      let ctx = this;
      this.$store
        .dispatch("get_user_types")
        .then(resp => {
          this.fullUserTypes = resp.data;
          ctx.userTypes = resp.data.map(type => {
            return {
              code: type.id,
              text: type.type[0].toUpperCase() + type.type.slice(1)
            };
          });
          this.currentUserType = this.defaultUserType;
          this.loadCustomerData();
          this.loadCountries();
          this.setCanEdit();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    async update() {
      let self = this;
      this.customer.user_type_id = this.currentUserType.code;
      let updateData = JSON.parse(JSON.stringify(self.customer));

      if (updateData.city) {
        updateData.city_id = updateData.city.id;
      } else {
        updateData.town_name = self.manualCity;
      }

      if (updateData.country) {
        updateData.country_id = updateData.country.id;
      }

      delete updateData.city;
      delete updateData.country;

      this.$store
        .dispatch("update_customer_request", {
          data: updateData,
          id: self.$route.params.id
        })
        .then(response => {
          if (self.local_avatar) {
            let avatarData = new FormData();
            avatarData.append("avatar_path", self.local_avatar);
            self.$store
              .dispatch("update_user_avatar", {
                avatar: avatarData,
                id: response.data.data.id
              })
              .then(resp => {
                self.customer.avatar_path = resp.data.data.avatar_path;
                self.local_avatar = null;

                this.$root.$emit("modalShow", {
                  type: "info",
                  text: "Successfully updated"
                });
                this.$router.push("/customers").catch(() => {});
              })
              .catch(err => {
                this.$root.$emit("modalShow", { text: err, type: "error" });
              });
          } else {
            this.$root.$emit("modalShow", {
              type: "info",
              text: "Successfully updated"
            });
            this.$router.push("/customers").catch(() => {});
          }

          if (response.data.data.id === self.$store.getters.user.id) {
            let profile = response.data.data;

            localStorage.setItem("user", JSON.stringify(profile));

            self.$store.commit("auth_success", { token: false, profile });
          }
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    goBack() {
      window.history.length
        ? this.$router.go(-1)
        : this.$router.push({ name: "CRMManageCustomer" }).catch(() => {});
    },
    deleteCustomer() {
      this.$dialog
        .confirm("Are you sure you want to delete this customer")
        .then(() => {
          this.$store
            .dispatch("delete_user_request", this.customer.id)
            .then(response => {
              this.$router.push({ name: "CRMCustomerList" }).catch(() => {});
              this.$root.$emit("modalShow", {
                type: "info",
                text: response.data.message
              });
            })
            .catch(error => {
              this.$root.$emit("modalShow", { type: "error", text: error });
            });
        })
        .catch(() => {});
    }
  },
  computed: {
    formatGender() {
      if (this.customer.gender === "free") {
        return "Gender Free";
      }
      if (!this.customer.gender) {
        return "";
      }
      return (
        this.customer.gender[0].toUpperCase() + this.customer.gender.substr(1)
      );
    },
    canEdit() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 2) {
          return permission.edit === 1;
        }
      }
      return false;
    },
    regDate() {
      let timestamp = this.$store.getters.customer["created_at_timestamp"];
      if (timestamp) {
        return this.$moment.unix(timestamp).format("DD.MM.YYYY");
      }
      return this.$store.getters.customer.created_at || "";
    },
    fullName() {
      return this.customer.l_name + " " + this.customer.f_name;
    },
    avatar() {
      return this.customer.avatar_path
        ? STORAGE_ENDPOINT + this.customer.avatar_path
        : null;
    },
    hasAvatar() {
      return !!this.customer.avatar_path;
    },
    dropzoneSettings() {
      return {
        url: `rand`,
        uploadMultiple: false,
        paramName: "avatar_path",
        autoProcessQueue: false,
        addRemoveLinks: true
      };
    },
    userType() {
      let self = this;
      let types = this.$store.getters.userTypes;
      if (types.length > 0) {
        types = types.filter(type => {
          return type.id === self.customer.user_type_id;
        });
        if (types[0]) {
          return types[0].type[0].toUpperCase() + types[0].type.slice(1);
        }
      }
      return "Wait..";
    },
    mobile_phone() {
      if (this.customer.mobile_phone) {
        return `+${this.customer.mobile_phone}`;
      }
      return "";
    },
    lastLogin() {
      let timestamp = this.customer["last_login_timestamp"];
      if (timestamp) {
        return this.$moment.unix(timestamp).format("DD.MM.YYYY HH:mm:ss");
      }
      return this.customer.last_login
        ? this.customer.last_login
        : "Not login yet";
    },
    getAge() {
      return !!this.customer.biometric_data &&
        !!this.customer.biometric_data.age
        ? this.customer.biometric_data.age
        : "Not set";
    },
    getHeight() {
      if (this.customer.biometric_data) {
        // eslint-disable-next-line
        this.height = this.customer.biometric_data.height;
      }
      return this.customer.biometric_data
        ? this.customer.biometric_data.height
        : "";
    },
    getWeight() {
      if (this.customer.biometric_data) {
        // eslint-disable-next-line
        this.weight = this.customer.biometric_data.weight;
      }
      return this.customer.biometric_data
        ? this.customer.biometric_data.weight
        : "";
    },
    getGenders() {
      return [
        {
          code: "male",
          label: "Male"
        },
        {
          code: "female",
          label: "Female"
        },
        {
          code: "free",
          label: "Gender Free"
        }
      ];
    }
  },
  watch: {
    customer: {
      handler: function(val) {
        let ctx = this;
        if (this.getGenders.length > 0) {
          for (let gender of this.getGenders) {
            if (gender.code === val.gender) {
              ctx.currentGender = gender;
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    currentGender(gender) {
      if (gender) {
        this.customer.gender = gender.code;
      }
    },
    currentUserType(type) {
      let businessTypes = _.filter(this.fullUserTypes, {
        business_type: _.find(this.$store.getters.userBusinessTypes, {
          name: "b2b"
        }).id
      }).map(t => t.id);
      if (businessTypes.includes(type.code)) {
        //this.$parent.customerType = "b2b";
        this.$root.$emit("modalShow", {
          type: "confirm",
          text: "Are you sure want to change account business type to B2B?",
          options: {
            confirmType: "set_b2b_type"
          }
        });
      }
    }
  }
};
</script>
