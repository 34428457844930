<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMSupportEntryCreate' }"
        active-class="is-active"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add Support Entry
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Admin \ Support groups</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <sl-vue-tree
                  ref="tree"
                  v-model="nodes"
                  @drop="sort()"
                  @nodeclick="showCategory($event)"
                >
                  <template v-if="false" v-slot:draginfo></template>
                  <template v-slot:title="{ node }">
                    <button
                      v-if="showCategoryIcon(node)"
                      class="btn btn-burger"
                    ></button>
                    <i v-if="showNewsIcon(node)" class="fa fa-newspaper-o"></i>
                    <template>
                      {{ node.title }}
                    </template>
                  </template>
                  <template v-slot:toggle></template>
                </sl-vue-tree>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import SlVueTree from "sl-vue-tree";

export default {
  name: "CRMSupports",
  components: {
    CRMLayout,
    SlVueTree
  },
  data() {
    return {
      nodes: []
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    showNewsIcon(node) {
      return node.isLeaf;
    },
    showCategoryIcon(node) {
      return !node.isLeaf;
    },
    loadCategories() {
      this.$store
        .dispatch("get_support_categories_request", { withChildren: true })
        .then(response => {
          this.nodes = response.data.categories.map(category => {
            let children = [];
            if (category.supports.length > 0) {
              for (let article of category.supports) {
                children.push({
                  title: article.title,
                  isLeaf: true,
                  data: article
                });
              }
            }
            let categoryChildren =
              category.children.length > 0
                ? category.children.map(cat => {
                    return this.mapCategories(cat);
                  })
                : [];
            children = [...children, ...categoryChildren];
            return {
              title: category.title,
              isExpanded: true,
              children: children,
              data: category
            };
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    mapCategories(category) {
      let children = [];
      if (category.supports.length > 0) {
        for (let article of category.supports) {
          children.push({
            title: article.title,
            isLeaf: true,
            data: article
          });
        }
      }
      let categoryChildren =
        category.children.length > 0
          ? category.children.map(cat => {
              return this.mapCategories(cat);
            })
          : [];
      children = [...children, ...categoryChildren];
      return {
        title: category.title,
        isExpanded: true,
        children: children,
        data: category
      };
    },
    showCategory(node) {
      // eslint-disable-next-line
      if (node.data.hasOwnProperty("id") && node.isLeaf) {
        this.$router.push(`/support/${node.data.id}`).catch(() => {});
      } else {
        this.$router.push(`/supports/categories/${node.data.id}`).catch(() => {});
      }
    },
    sort() {
      let sortObject = {};
      let isValidSort = true;
      let errorSort = "";
      this.$refs.tree.traverse(node => {
        if (node.level >= 3 && !node.isLeaf) {
          errorSort = "Tree can only have 2 levels of categories";
          isValidSort = false;
        }
        if (node.level < 3 && node.isLeaf) {
          isValidSort = false;
          errorSort = "Support can only be at level 2 categories";
        }
        if (!node.isLeaf && node.level === 1) {
          if (!sortObject[node.data.id]) {
            sortObject[node.data.id] = {
              children: {},
              supports: {}
            };
          }

          if (node.children) {
            sortObject[node.data.id].children = this.getChildren(node.children);
            sortObject[node.data.id].supports = this.getChildren(
              node.children,
              true
            );
          }
        }
      });
      if (!isValidSort) {
        this.$root.$emit("modalShow", { type: "error", text: errorSort });
        this.loadCategories();
        return;
      }
      let ctx = this;

      this.$store
        .dispatch("sort_support_entry_request", sortObject)
        .then(() => {
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Sorted successfully"
          });
        })
        .catch(error => {
          ctx.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    getChildren(nodeChildren, supports = false) {
      let children = {};
      let supportsArray = [];

      for (let child of nodeChildren) {
        if (!supports && !child.isLeaf) {
          let newChild = {};
          newChild = {
            supports: [],
            children: []
          };

          if (child.children) {
            newChild.children = this.getChildren(child.children);
            newChild.supports = this.getChildren(child.children, true);
          }
          children[child.data.id] = newChild;
        }

        if (child.isLeaf && supports) {
          supportsArray.push(child.data.id);
        }
      }

      if (!supports) {
        return children;
      }

      return supportsArray;
    }
  }
};
</script>

<style>
@import "../../node_modules/sl-vue-tree/src/sl-vue-tree-minimal.css";
@import "../../node_modules/sl-vue-tree/src/sl-vue-tree-dark.css";

.sl-vue-tree.sl-vue-tree-root {
  color: #000000;
  border: none;
  background-color: transparent;
  /*width: 100%;*/
  padding-left: 30px;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  background-color: transparent;
  color: #000000;
  border: none;
}

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  color: #000000;
  cursor: pointer;
}

.sl-vue-tree-sidebar {
  padding-left: 30px;
}

.sl-vue-tree-node-item {
  line-height: 39px;
}
</style>

<style scoped>
.btn-burger {
  margin-right: 10px !important;
}

@media screen and (max-width: 767px) {
  .sl-vue-tree.sl-vue-tree-root {
    padding-left: 0;
  }
}
</style>
