<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'DealersCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        v-text="'Create Dealer'"
      />
    </template>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Dealers</h5>
          </div>
          <div class="main__fs scroll">
            <table
              v-if="listItems.length > 0"
              class="table__typical table__dealers"
            >
              <tbody>
                <tr>
                  <th
                    class="pointer"
                    @click="sortRows('id')"
                  >
                    <span>ID</span>
                    <span :class="arrowClass('id')"></span>
                  </th>
                  <th
                    class="pointer"
                    @click="sortRows('name')"
                  >
                    <span>Name</span>
                    <span :class="arrowClass('name')"></span>
                  </th>
                  <th>
                    <span>Published</span>
                    <span class="btn-arrow hidden"></span>
                  </th>
                  <th
                    class="pointer"
                    @click="sortRows('created_at')"
                  >
                    <span>Created</span>
                    <span :class="arrowClass('created_at')"></span>
                  </th>
                  <th
                    class="pointer"
                    @click="sortRows('updated_at')"
                  >
                    <span>Updated</span>
                    <span :class="arrowClass('updated_at')"></span>
                  </th>
                </tr>

                <tr
                  v-for="item in listItems"
                  :key="item.id"
                  class="pointer"
                  @click="$router.push({ name: 'DealersUpdate', params: { id: item.id } })"
                >
                  <td>
                    <span>
                      {{ item.id }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.name }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.active ? "Yes" : "No" }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.created_at }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.updated_at }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="listItems.length > 0"
            class="wrapTotal"
          >
            <paginator
              :current-page="currentPage"
              :last-page="lastPage"
              @changepage="changePage"
            >
            </paginator>
            <div class="totalText">Total: {{ total }}</div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "@/components/CRMLayout";
import Paginator from "@/components/partials/Paginator";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "@/store/helpers/parseError";

export default {
  name: "DealersList",
  components: { CRMLayout, Paginator },
  data() {
    return {
      listItems: [],
      currentPage: 1,
      lastPage: 1,
      total: 0,
      orderBy: "id",
      orderTo: "asc",
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      axios
        .get(API_ENDPOINT + "crm/dealers", {
          params: {
            order_by: this.orderBy,
            order_to: this.orderTo,
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.listItems = response.data.data;
          this.currentPage = response.data.meta.current_page;
          this.lastPage = response.data.meta.last_page;
          this.total = response.data.meta.total;

          if (this.total == 0) {
            this.$router.push({ name: "DealersCreate" }).catch(() => {});
          }
        })
        .catch((err) => {
          this.$root.$emit("modalShow", { text: parseError(err), type: "error" });
        });
    },
    changePage(page) {
      this.currentPage = page;

      this.loadItems();
    },
    arrowClass(field) {
      if (field === this.orderBy) {
        let classes = "btn-arrow centered";
        return this.orderTo === "asc" ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    },
    sortRows(field) {
      if (this.orderBy === field) {
        this.orderTo = this.orderTo === "asc" ? "desc" : "asc";
      } else {
        this.orderTo = "asc";
      }

      this.orderBy = field;

      this.loadItems();
    },
  },
};
</script>

<style scoped lang="scss">
.table__typical {
  td {
    padding: 20px;
  }

  th {
    padding: 8px 20px 8px 20px;
  }
}

.btn-arrow {
  &.hidden {
    display: inline-block !important;
  }
}
</style>
