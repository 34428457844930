<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMPartCategoriesCreate' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add Category
      </router-link>
    </template>

    <template v-slot:header-title>
      <span>Products Part categories</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Part categories</h5>
          </div>
          <div class="scroll">
            <table v-if="hasCategories" class="table__typical table__orders">
              <tbody>
                <tr>
                  <th class="pointer" @click="sortRows('id')">
                    <span>ID</span>
                    <span :class="arrowClass('id')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('en_title')">
                    <span>Title</span>
                    <span :class="arrowClass('en_title')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('as_option')">
                    <span>Available as option</span>
                    <span :class="arrowClass('as_option')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('parts_count')">
                    <span>SKU’s</span>
                    <span :class="arrowClass('parts_count')"></span>
                  </th>
                  <th>Added by</th>
                  <th class="pointer" @click="sortRows('created_at')">
                    <span>Created</span>
                    <span :class="arrowClass('created_at')"></span>
                  </th>
                </tr>

                <tr
                  v-for="category in categories"
                  :key="category.id"
                  @click="showCategory($event, category)"
                >
                  <td>
                    <a :href="`/products/parts/categories/${category.id}`">
                      {{ category.id }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/products/parts/categories/${category.id}`">
                      {{ category.en_title }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/products/parts/categories/${category.id}`">
                      {{ isAsOption(category.as_option) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/products/parts/categories/${category.id}`">
                      {{ category.parts.length }}
                    </a>
                  </td>
                  <td>
                    <a
                      href=""
                      @click.prevent="
                        $router.push('/customers/' + category.user.id)
                      "
                    >
                      {{ category.user.f_name }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/products/parts/categories/${category.id}`">
                      {{ category.created_at }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By author</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentAuthor"
                  :options="authors"
                  label="label"
                  placeholder="Select Author"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Available as option</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes"
                    :checked="as_option_filter === 1"
                    type="checkbox"
                    @change="acceptOptionalFilter(1)"
                  />
                  <label for="yes">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no"
                    :checked="as_option_filter === 0"
                    type="checkbox"
                    @change="acceptOptionalFilter(0)"
                  />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes1"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes1">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no1"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-bold btn-fw btn-sm"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Paginator from "./partials/Paginator";
import vSelect from "vue-select";
// import $ from "jquery";

export default {
  name: "CRMPartCategories",
  components: { vSelect, layout: CRMLayout, DatePicker, Paginator },
  data() {
    return {
      categories: [],
      as_option_filter: null,
      createdAtFilter: null,
      archivedFilter: null,
      currentAuthor: {
        code: 0,
        label: "All"
      },
      authors: [],
      currentPage: 1,
      lastPage: 1,
      updateList: true,
      total: 0,
      perPage: 0,
      reseting: false,
      order_by: "id",
      ordering: true,
      previous_order_field: null
    };
  },
  created() {
    this.loadCategories();
    this.loadAuthors();
  },
  methods: {
    showCategory(event, category) {
      if (event.target.localName === "td") {
        this.$router.push({
          path: `/products/parts/categories/${category.id}`
        }).catch(() => {});
      }
    },
    loadAuthors() {
      let ctx = this;
      this.$store
        .dispatch("get_authors_request")
        .then(response => {
          ctx.authors = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(author => {
              return {
                code: author.id,
                label:
                  author.f_name[0].toUpperCase() +
                  author.f_name.substr(1) +
                  " " +
                  (author.l_name ? author.l_name[0].toUpperCase() +
                  author.l_name.substr(1) : '')
              };
            })
          ];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    acceptOptionalFilter(value) {
      if (this.as_option_filter === null) {
        this.as_option_filter = value;
      } else if (value === this.as_option_filter) {
        this.as_option_filter = null;
      } else {
        this.as_option_filter = value;
      }

      this.updateList = true;
      this.loadCategories();
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadCategories();
    },
    isAsOption(as_option) {
      if (as_option === 0) {
        return "No";
      } else {
        return "Yes";
      }
    },
    loadCategories() {
      let ctx = this;

      let options = {
        parameters: []
      };

      options.parameters.push(`page=${this.currentPage}`);

      if (ctx.as_option_filter !== null) {
        options.parameters.push(
          `as_option=${ctx.as_option_filter ? "yes" : "no"}`
        );
      }

      if (!!ctx.currentAuthor && ctx.currentAuthor.code !== 0) {
        options.parameters.push(`created_by=${ctx.currentAuthor.code}`);
      }

      if (ctx.createdAtFilter) {
        options.parameters.push(`created_at=${ctx.createdAtFilter}`);
      }

      if (this.archivedFilter) {
        options.parameters.push(`archived=true`);
      }

      if (ctx.order_by) {
        options.parameters.push(`order_by=${ctx.order_by}`);
      }

      if (ctx.ordering) {
        options.parameters.push(`order=${ctx.ordering ? "desc" : "true"}`);
      }

      this.$store
        .dispatch("get_part_categories_with_pagination_request", options)
        .then(response => {
          if (ctx.updateList) {
            ctx.categories = response.data.data;
            ctx.updateList = true;
            ctx.currentPage = response.data["current_page"];
            ctx.lastPage = response.data["last_page"];
            ctx.total = response.data.total;
            ctx.perPage = response.data["per_page"];

            if (ctx.categories.length < 6) {
              window.$("body, html").animate({ scrollTop: 0 }, 500, "swing");
            }
          } else {
            ctx.categories = [...ctx.categories, ...response.data.data];
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
    },
    resetFilters() {
      this.updateList = true;
      this.as_option_filter = null;
      this.createdAtFilter = null;
      this.archivedFilter = null;
      this.reseting = true;
      this.currentAuthor = {
        code: 0,
        label: "All"
      };
      this.currentPage = 1;
      this.lastPage = 1;
      this.order_by = "id";
      this.ordering = true;
      this.loadCategories();
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.loadCategories();
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    }
  },
  computed: {
    hasCategories() {
      return this.categories.length > 0;
    }
  },
  watch: {
    createdAtFilter() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.loadCategories();
      }
    },
    currentAuthor: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.loadCategories();
      }
    },
    currentPage: function(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadCategories();
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 1300px) {
  .table__orders td:last-child,
  .table__orders th:last-child {
    width: 20%;
  }

  .scroll table {
    min-width: 900px;
  }
}
</style>
