<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMOrderCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        v-text="'Add order'"
      />
      <a
        class="btn btn-white btn-reset add-models-btn"
        @click="exportAllOrders"
      >
        Export
      </a>
    </template>
    <template v-slot:content>
      <div class="main__c">
        <div v-if="orders.length > 0" class="main__c-in">
          <div class="main__headline">
            <h5>Orders</h5>
          </div>
          <div class="scroll mb-45">
            <table
              v-if="orders.length > 0"
              class="table__typical table__orders"
            >
              <thead>
                <tr>
                  <th class="pointer id" @click="sortRows('id')">
                    <span>ID</span>
                    <span :class="arrowClass('id')"></span>
                  </th>
                  <th>
                    <span>Last Name</span>
                    <span :class="arrowClass('l_name')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('created_at')">
                    <span>Date</span>
                    <span :class="arrowClass('created_at')"></span>
                  </th>
                  <th>
                    <span>Time</span>
                  </th>
                  <th class="with-popup">
                    <div class="d-flex">
                      <span class="popup-wrapper">
                        <span
                          class="info-icon"
                          @mouseleave="togglePaymentMethodsPopup(false)"
                          @mouseover="togglePaymentMethodsPopup(true)"
                          >i</span
                        >
                        <span v-if="showPaymentMethodPopup" class="popup-block">
                          <span>CC - Credit Card</span>
                          <span>AP - Apple Pay</span>
                          <span>BT - Bank Transfer</span>
                          <span>PP - PayPal</span>
                        </span>
                      </span>
                      Payment Method
                    </div>
                  </th>
                  <th>
                    <span>Paid</span>
                  </th>
                  <th class="pointer" @click="sortRows('quantity')">
                    <span>SKU's</span>
                    <span :class="arrowClass('quantity')"></span>
                  </th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="order in orders"
                  :key="order.id"
                  @click="showOrder($event, order)"
                >
                  <td>
                    <a :href="`/orders/${order.id}`">{{ order.id }}</a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderCreatorName(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderCreatedAtDate(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderCreatedAtTime(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderPaymentMethods(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderPaid(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">{{ orderSku(order) }}</a>
                  </td>
                  <td>
                    <a v-if="canViewDocs()" @click.prevent="downloadPdf(order)">
                      <span>pdf</span>
                    </a>
                    <span v-else>No access</span>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`" :class="{ 'saved-order': order.order_status == 1 }">
                      {{ order.order_status == 1 ? 'Saved' : getOrderStatus(order) }}
                    </a>
                  </td>
                  <td>
                    <a :href="`/orders/${order.id}`">
                      {{ getOrderCountry(order) }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapTotal">
            <paginator
              :current-page="currentPage"
              :last-page="lastPage"
              @changepage="changePage"
            />
            <div class="totalText">
              Total: {{ total }}, Paid {{ totalPaid }},
              {{ totalPaidToday }} paid today
            </div>
          </div>
        </div>
      </div>
      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>
        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Saved orders</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input id="saved_yes" :checked="savedFilter" type="checkbox" @change="acceptSavedFilter(true)" />
                  <label for="saved_yes">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input id="saved_no" :checked="savedFilter !== null && savedFilter === false
                    " type="checkbox" @change="acceptSavedFilter(false)" />
                  <label for="saved_no">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Filter name</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <label for="from">from</label>
                <input id="from" v-model="priceFromFilter" type="text" />
              </div>
              <div class="inp-group">
                <label for="to">to</label>
                <input id="to" v-model="priceToFilter" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By payment method</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentPaymentMethod"
                  :options="paymentMethods"
                  label="label"
                  placeholder="Select payment method"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By user name or e-mail</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="creator" title="Creator" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Frame number</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input
                  v-model="frame_number_filter"
                  title="Frame number"
                  type="text"
                  @input="debounceChange($event)"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By status</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentStatus"
                  :options="statuses"
                  label="label"
                  placeholder="Select order status"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By bike size</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentSize"
                  :options="sizes"
                  label="label"
                  placeholder="Select order status"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By country</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentCountry"
                  :options="countries"
                  label="label"
                  placeholder="Select order status"
                />
              </div>
            </div>
          </div>
          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-bold btn-sm"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import Paginator from "./partials/Paginator";
import { debounce } from "debounce";

export default {
  name: "CRMDashBoard",
  components: { CRMLayout, vSelect, Paginator },
  data() {
    return {
      disableFilters: false,
      orders: [],
      priceFromFilter: null,
      priceToFilter: null,
      currentPaymentMethod: {
        label: "All",
        code: 0
      },
      currentStatus: {
        label: "All",
        code: 0
      },
      currentCountry: {
        label: "All",
        code: 0
      },
      currentSize: {
        label: "All",
        code: 0
      },
      sizes: [],
      currentCreator: null,
      createdAtFilter: null,
      currentPage: 1,
      lastPage: 1,
      updateList: true,
      total: 0,
      totalPaid: 0,
      totalPaidToday: 0,
      perPage: 0,
      ordering: true,
      order_by: "id",
      previous_order_field: null,
      frame_number_filter: null,
      paymentMethods: [],
      statuses: [],
      countries: [],
      creator: null,
      admin: JSON.parse(localStorage.getItem("user")),
      showPaymentMethodPopup: false,
      savedFilter: null
    };
  },
  mounted() {
    this.loadOrders();
    this.loadPaymentMethods();
    this.loadOrderStatuses();
    this.loadCountries();
    this.loadCountOrders();
    this.loadSizes();
  },
  methods: {
    debounceChange: debounce(function() {
      this.loadOrders();
    }, 1000),
    showOrder(event, order) {
      if (event.target.localName === "td") {
        this.$router.push(`/orders/${order.id}`).catch(() => {});
      }
    },
    canViewDocs() {
      for (let permission of this.admin["user_type"].premissions) {
        if (permission["premisson_id"] === 5) {
          return permission.view === 1;
        }
      }
    },
    downloadPdf(order) {
      let ctx = this;
      this.$store
        .dispatch("download_order_pdf_request", order.id)
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          let fileName = `pro-forma-${order.id}`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    getOrderStatus(order) {
      return (
        order.status.status[0].toUpperCase() + order.status.status.substr(1)
      );
    },
    getOrderCountry(order) {
      // eslint-disable-next-line
      if (order.hasOwnProperty("delivery_address")) {
        if (
          order.delivery_address &&
          // eslint-disable-next-line
          order.delivery_address.hasOwnProperty("country") &&
          order.delivery_address.country &&
          order.delivery_address.country["country_name"]
        ) {
          return order.delivery_address.country["country_name"];
        }
      }
      // eslint-disable-next-line
      if (order.hasOwnProperty("invoice_address")) {
        if (
          order.invoice_address &&
          // eslint-disable-next-line
          order.invoice_address.hasOwnProperty("country") &&
          order.invoice_address.country &&
          order.invoice_address.country["country_name"]
        ) {
          return order.invoice_address.country["country_name"];
        }
      }
      return "Country not set";
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
    },
    loadOrderStatuses() {
      let ctx = this;
      this.$store
        .dispatch("get_order_statuses_request")
        .then(response => {
          ctx.statuses = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(method => {
              return {
                label: method.status,
                code: method.id
              };
            })
          ];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadPaymentMethods() {
      let ctx = this;
      this.$store
        .dispatch("get_payment_methods_request")
        .then(response => {
          ctx.paymentMethods = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(method => {
              return {
                label: method.payment_method,
                code: method.id
              };
            })
          ];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadSizes() {
      let ctx = this;
      this.$store
        .dispatch("get_part_category_request", 3)
        .then(response => {
          ctx.sizes = [
            ...[{ code: 0, label: "All" }],
            ...response.data.parts
              .filter(part => part.category === 3)
              .map(method => {
                return {
                  label: method.title_cms,
                  code: method.id
                };
              })
          ];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getOrderCreatorName(order) {
      return (
        order.user.f_name +
        " " +
        (order.user.l_name ? order.user.l_name.substr(0, 1) : "")
      );
    },
    getOrderCreatedAtDate(order) {
      return this.$moment(order.created_at).format("DD.MM.YY");
    },
    getOrderCreatedAtTime(order) {
      return this.$moment(order.created_at).format("HH:mm:ss");
    },
    getOrderPaymentMethods(order) {
      let paymentMethods = [];
      let orderPayments = order["payments"];
      if (Array.isArray(orderPayments) && orderPayments.length > 0) {
        orderPayments = orderPayments.filter(payment => {
          return payment.status === "paid" || payment.status === "uploaded";
        });
        if (orderPayments.length > 0) {
          for (let method of orderPayments) {
            if (method.payment_method && method.payment_method.payment_method) {
              switch (method.payment_method.payment_method) {
                case "PayPal":
                  paymentMethods.push("PP");
                  break;
                case "Bank Transfer":
                  paymentMethods.push("BT");
                  break;
                case "Credit Card":
                  paymentMethods.push("CC");
                  break;
                case "Apple Pay":
                  paymentMethods.push("AP");
                  break;
              }
            }
          }
        }
      }

      if (paymentMethods.length > 0) {
        return paymentMethods.join(", ");
      }

      return "Not paid";
    },
    getOrderPaid(order) {
      return this.$crConverter(order["paid_sum"] / 100 || 0, "EUR");
    },
    orderSku(order) {
      if (
        order["basket"] &&
        order["basket"].items &&
        order["basket"].items.length > 0
      ) {
        let sku = 0;
        for (let item of order["basket"].items) {
          sku += item["qty"];
        }
        return sku;
      }
      return 0;
    },
    loadOrders() {
      let ctx = this;

      let options = {
        parameters: []
      };
      options.parameters.push(`page=${this.currentPage}`);

      if (!!ctx.currentPaymentMethod && ctx.currentPaymentMethod.code !== 0) {
        options.parameters.push(
          `payment_method_id=${ctx.currentPaymentMethod.code}`
        );
      }
      if (!!ctx.currentSize && ctx.currentSize.code !== 0) {
        options.parameters.push(`bike_size=${ctx.currentSize.code}`);
      }
      if (ctx.priceFromFilter) {
        options.parameters.push(`from=${ctx.priceFromFilter}`);
      }

      if (ctx.priceToFilter) {
        options.parameters.push(`to=${ctx.priceToFilter}`);
      }

      if (ctx.creator) {
        options.parameters.push(`created_by=${ctx.creator}`);
      }
      if (ctx.frame_number_filter) {
        options.parameters.push(`frame_number=${ctx.frame_number_filter}`);
      }

      if (!!ctx.currentStatus && ctx.currentStatus.code !== 0) {
        options.parameters.push(`status_id=${ctx.currentStatus.code}`);
      }

      if (!!ctx.currentCountry && ctx.currentCountry.code !== 0) {
        options.parameters.push(`country_id=${ctx.currentCountry.code}`);
      }

      if (ctx.order_by) {
        options.parameters.push(`order_by=${ctx.order_by}`);
      }
      options.parameters.push(`order=${ctx.ordering ? "desc" : "asc"}`);

      if (null !== this.savedFilter) {
        options.parameters.push('saved=' + (this.savedFilter ? 1 : 0));
      }

      this.$store
        .dispatch("get_orders_request", options)
        .then(response => {
          this.disableFilters = false;
          ctx.orders = response.data.data;
          ctx.currentPage = response.data["current_page"];
          ctx.lastPage = response.data["last_page"];
          ctx.total = response.data.total;
          ctx.perPage = response.data["per_page"];
        })
        .catch(error => {
          ctx.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    loadCountOrders() {
      let ctx = this;
      this.$store
        .dispatch("get_count_orders_request")
        .then(response => {
          ctx.totalPaid = response.data.totalPaid;
          ctx.totalPaidToday = response.data.totalPaidToday;
        })
        .catch(error => {
          ctx.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.loadOrders();
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    },
    togglePaymentMethodsPopup(value) {
      this.showPaymentMethodPopup = value;
    },
    acceptSavedFilter(status) {
      this.savedFilter = this.savedFilter === status ? null : !!status;

      this.loadOrders();
    },
    resetFilters() {
      this.updateList = true;
      this.currentPage = 1;
      this.order_by = "id";
      this.ordering = true;
      this.currentStatus = {
        code: 0,
        label: "All"
      };
      this.currentSize = {
        code: 0,
        label: "All"
      };
      this.frame_number_filter = null;
      this.currentPaymentMethod = {
        code: 0,
        label: "All"
      };
      this.currentCountry = {
        code: 0,
        label: "All"
      };
      this.lastPage = 1;
      this.savedFilter = false;

      this.loadOrders();
    },
    loadCountries() {
      let ctx = this;
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          ctx.countries = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(country => {
              return {
                code: country.id,
                label: country.country_name
              };
            })
          ];
        })
        .catch(err => {
          console.log(err);
        });
    },
    exportAllOrders() {
      this.$dialog
        .confirm(`The report will be sent to ${this.$store.getters.user.email}.`)
        .then(() => {
          this.$store
            .dispatch("orderList/exportAllOrdersToExcel")
            .then(() => {
              this.$root.$emit("modalShow", {
                text:
                  "As soon as the report is ready, you will receive it by email",
                type: "info"
              });
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });
            });
        })
        .catch(() => {});
    },
    debounce(f, ms) {
      let isCoolDown = false;

      return function() {
        if (isCoolDown) return;

        f.apply(this, arguments);

        isCoolDown = true;

        setTimeout(() => (isCoolDown = false), ms);
      };
    }
  },
  watch: {
    currentPage: function(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadOrders();
    },
    priceFromFilter: function() {
      this.updateList = true;
      this.loadOrders();
    },
    priceToFilter: function() {
      this.updateList = true;
      this.loadOrders();
    },
    frame_number_filter: function(frame) {
      this.frame_number_filter = frame;
    },
    creator: function() {
      this.updateList = true;
      this.loadOrders();
    },
    currentStatus: function() {
      this.updateList = true;
      this.loadOrders();
    },
    currentCountry: function() {
      this.updateList = true;
      this.loadOrders();
    },
    currentSize: function() {
      this.updateList = true;
      this.loadOrders();
    },
    currentPaymentMethod: function() {
      this.updateList = true;
      this.loadOrders();
    }
  }
};
</script>

<style lang="scss" scoped>
.table__orders td:first-child,
.table__orders th:first-child {
  padding-left: 8px;
  padding-right: 8px;
}

.table__orders td:last-child,
.table__orders th:last-child {
  width: 12%;
}

.table__orders td:nth-child(2),
.table__orders th:nth-child(2) {
  width: 12%;
  max-width: 12%;
}

.table__orders th:nth-child(7),
.table__orders td:nth-child(7) {
  width: 50px;
}

.table__typical td,
.table__typical th {
  padding-right: 4px;
  padding-left: 4px;
}

.table__typical {
  .with-popup {
    position: relative;

    .d-flex {
      display: flex;
      align-items: center;
      white-space: normal;
    }

    .popup-wrapper {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      .info-icon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-right: 5px;
        border-radius: 100%;
        border: 2px solid #6c6c6c;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        color: #6c6c6c;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .popup-block {
      position: absolute;
      background: #fffdee;
      box-shadow: 0 0 2px 0 rgba(130, 130, 130, 0.5);
      color: #000000;
      display: block;
      padding: 8px 12px;
      border-radius: 5px;
      width: 180px;
      z-index: 100;
      margin-top: 6px;
      right: calc(100% + 6px);
      top: -10px;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: #fffdee;
        border-radius: 5px;
      }

      &:before {
        content: "";
        height: 7px;
        width: 7px;
        position: absolute;
        bottom: calc(100% - 18px);
        box-shadow: 0 0 2px 0 rgba(130, 130, 130, 0.5);
        left: calc(100% - 4px);
        transform: rotate(45deg);
        z-index: -3;
        background: #fffdee;
      }

      span {
        display: block;
        width: 100%;
        margin: 4px 0;
        font-weight: normal;
        font-size: 14px;
      }
    }
  }
}

.table__typical td a,
.table__typical th a {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>

<style lang="scss">
.main__r .vs__selected {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.main__r .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

//.main__r .vs__search {
//  padding: 0;
//}

.main__r .vs__dropdown-menu {
  li {
    max-width: 100%;
    white-space: normal;
  }
}

/* .saved-order {
  background-color: #C2E900;
  padding: 4px 8px;
  border-radius: 12px;
} */
</style>
