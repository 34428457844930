<template>
  <CRMLayout>
    <template v-slot:header-back-btn></template>
    <template v-slot:header-title>
      <span>{{ id ? "Update" : "Create" }} Dealer</span>
    </template>
    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Basic information</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-model="model.name"
                      autofocus
                      type="text"
                    />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Published:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-active"
                      v-model="model.active"
                      type="checkbox"
                    />
                    <label for="checkbox-active"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Description:</span>
                </div>
                <!-- .main__content-item_l -->
                <div class="main__content-item_r">
                  <div class="inp-group inp-group__textarea inp-group__xl">
                    <textarea v-model="model.description" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div
                v-if="countries.length > 0"
                class="main__content-item main__content-item_center"
              >
                <div class="main__content-item_l">
                  <span>Countries:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <multiselect
                      v-model="model.countries"
                      :allow-empty="false"
                      :internal-search="true"
                      label="country_name"
                      :multiple="true"
                      :options="countries"
                      placeholder="Select countries"
                      :showNoResults="false"
                      track-by="id"
                    />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content main__content-item_center">
            <div class="main__content-headline">
              <h5>Contacts:</h5>
            </div>
            <!-- .main__content-headline -->
            <div class="main__content-in">
              <draggable
                v-model="model.contacts"
                :sort="true"
                handle=".btn-burger"
                tag="div"
              >
                <div
                  v-for="(item, index) in model.contacts"
                  :key="item.id"
                  class="contacts-item"
                >
                  <email-block
                    v-if="item.type === 'email'"
                    v-model="model.contacts[index]"
                    @onRemove="removeContact(index)"
                  />
                  <phone-block
                    v-if="item.type === 'phone'"
                    v-model="model.contacts[index]"
                    @onRemove="removeContact(index)"
                  />
                  <website-block
                    v-if="item.type === 'website'"
                    v-model="model.contacts[index]"
                    @onRemove="removeContact(index)"
                  />
                </div>
              </draggable>
              <div class="main__content-item main__content-item_center btn-wrapper">
                <div class="main__content-item_l">
                  <span>Add contact:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="selected"
                      :clearable="false"
                      :options="options"
                      :searchable="false"
                      @input="addContact"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button
              class="btn btn-big btn-fw"
              @click="save"
            >
              <span><i>Save dealer</i></span>
            </button>
            <button
              class="btn btn-big btn-fw"
              @click="back"
            >
              <span><i>Cancel</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "@/components/CRMLayout";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "@/store/helpers/parseError";
import { Multiselect } from "vue-multiselect";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import EmailBlock from "./email";
import PhoneBlock from "./phone";
import WebsiteBlock from "./website";

export default {
  name: "DealersForm",
  components: { CRMLayout, Multiselect, draggable, vSelect, EmailBlock, PhoneBlock, WebsiteBlock },
  data() {
    return {
      id: null,
      model: {
        name: "",
        active: true,
        description: "",
        contacts: [],
        countries: [],
      },
      countries: [],
      options: [
        {
          code: "email",
          label: "Email Address",
        },
        {
          code: "phone",
          label: "Phone Number",
        },
        {
          code: "website",
          label: "Website",
        },
      ],
      selected: null,
    };
  },
  mounted() {
    if (typeof this.$route.params.id !== "undefined") {
      this.loadItem(this.$route.params.id);
    }

    this.loadCountries();
  },
  methods: {
    loadItem(id) {
      axios
        .get(API_ENDPOINT + "crm/dealers/" + id)
        .then((response) => {
          this.id = response.data.id;

          this.model = {
            name: response.data.name,
            active: response.data.active,
            description: response.data.description,
            contacts: response.data.contacts,
            countries: response.data.countries,
          };
        })
        .catch((err) => {
          this.$root.$emit("modalShow", { text: parseError(err), type: "error" });
        });
    },
    loadCountries() {
      axios
        .get(API_ENDPOINT + "countries")
        .then((response) => {
          this.countries = response.data;
        })
        .catch((err) => {
          this.$root.$emit("modalShow", { text: parseError(err), type: "error" });
        });
    },
    save() {
      this.model.contacts = this.model.contacts.filter((value) => {
        return (
          (value.type === "email" && value.email.trim().length > 0) ||
          (value.type === "phone" && value.phone.trim().length > 0) ||
          (value.type === "website" && value.url.trim().length > 0)
        );
      });

      let data = { ...this.model };

      data.countries = data.countries.map((item) => item.id);

      axios
        .request({
          url: API_ENDPOINT + "crm/dealers" + (this.id ? "/" + this.id : ""),
          method: this.id ? "put" : "post",
          data: data,
        })
        .then(() => {
          this.$root.$emit("modalShow", { text: "Dealer " + (this.id ? "Updated" : "Created"), type: "info" });
          this.$router.push({ name: "DealersList" }).catch(() => {
          });
        })
        .catch((err) => {
          this.$root.$emit("modalShow", { text: parseError(err), type: "error" });
        });
    },
    back() {
      this.$router.push({ name: "DealersList" }).catch(() => {
      });
    },
    addContact(value) {
      this.model.contacts.push({
        type: value.code,
        phone: "",
        email: "",
        url: "",
        title: "",
      });

      this.selected = null;
    },
    removeContact(index) {
      this.model.contacts = this.model.contacts.filter((value, key) => key !== index);
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-item {
  margin-bottom: 15px;
}
</style>
