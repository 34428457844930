<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMVacancyCreate' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add Vacancy
      </router-link>
    </template>

    <template v-slot:header-title>
      <span>List of vacancies</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Vacancies</h5>
          </div>

          <table v-if="hasVacancies" class="table__typical table__orders">
            <thead>
              <tr>
                <th class="pointer">
                  <span>ID</span>
                  <span></span>
                </th>

                <th class="pointer">
                  <span>Title</span>
                </th>

                <th class="pointer">
                  <span>Created</span>
                </th>
              </tr>
            </thead>
            <draggable
              v-model="vacancies"
              :sort="true"
              tag="tbody"
              @change="sort"
            >
              <tr
                v-for="vacancy in vacancies"
                :key="vacancy.id"
                @click="showVacancy($event, vacancy)"
              >
                <td>{{ vacancy.id }}</td>
                <td>{{ vacancy.title }}</td>
                <td>{{ vacancy.created_at }}</td>
              </tr>
            </draggable>
          </table>

          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import draggable from "vuedraggable";

export default {
  name: "CRMVacancyList",
  components: { CRMLayout, Paginator, draggable },
  data: () => ({
    vacancies: [],
    currentPage: 1,
    lastPage: 1,
    updateList: true
  }),
  mounted() {
    this.loadVacancies();
  },
  computed: {
    hasVacancies() {
      return this.vacancies.length > 0;
    }
  },
  methods: {
    loadVacancies() {
      let ctx = this;
      this.$store
        .dispatch("get_vacancies_request", { page: this.currentPage })
        .then(response => {
          this.lastPage = response.data.meta["last_page"];
          this.currentPage = response.data.meta["current_page"];

          this.vacancies = response.data.data.map(item => {
            return {
              id: item.id,
              title: item.title,
              created_at: ctx.$moment(this.created_at).format("DD.MM.YYYY")
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadVacancies();
    },
    sort() {
      let sortable = Array.from(this.vacancies, vacancy => vacancy.id);
      this.$store.dispatch("sort_vacancy_request", sortable).catch(err => {
        this.$root.$emit("modalShow", { type: "error", text: err });
      });
    },
    showVacancy(event, vacancy) {
      if (event.target.localName === "td") {
        this.$router.push({ path: `/vacancies/${vacancy.id}` }).catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}

.table__orders td:nth-child(2),
.table__orders th:nth-child(2) {
  max-width: 100%;
  width: auto;
}

@media screen and (max-width: 767px) {
  .table__orders td:nth-child(2),
  .table__orders th:nth-child(2),
  .table__orders td:last-child,
  .table__orders th:last-child {
    width: 40%;
  }

  .table__typical td:first-child,
  .table__typical th:first-child {
    width: 20%;
    padding-left: 20px;
  }

  .main__c-in {
    padding-right: 20px;
  }
}
</style>
