<template>
  <CRMLayout>
    <template v-slot:header-title>
      Import parts count and price
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Import steps</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_end">
                <div class="main__content-item_l">
                  <span><b>Step 1:</b></span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <span
                    >Click <a href="" @click.prevent="getTemplate()">here</a> to
                    download import template</span
                  >
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_end">
                <div class="main__content-item_l">
                  <span><b>Step 2:</b></span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <span v-if="!!!file"
                    >Click <a href="#" @click.prevent="selectFile()">here</a> to
                    select file for import</span
                  >
                  <input
                    class="template-import"
                    style="display: none"
                    type="file"
                    @change="processFile($event)"
                  />
                  <span v-if="!!file"
                    >{{ file.name }}
                    <button class="btn-link" @click="deleteTempFile()">
                      delete
                    </button></span
                  >
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Tip:</span>
                </div>
                <!-- .main__content-item_l -->

                <div v-if="!uploaded" class="main__content-item_r">
                  <span>Columns:</span>
                  <ul class="list-unstyled">
                    <li v-for="(column, index) in columns" :key="index">
                      - {{ column }}
                    </li>
                  </ul>
                </div>
                <!-- .main__content-item_r -->
                <div
                  v-if="uploaded"
                  class="main__content-item_r main__content-item_r-block"
                >
                  <span>{{ created }} records will be added</span>
                  <span>{{ edited }} records will be edited</span>
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_end">
                <div class="main__content-item_l">
                  <span><b>Step 3:</b></span>
                </div>
                <!-- .main__content-item_l -->

                <div v-if="showConfirmMessage" class="main__content-item_r">
                  <span>Confirm changes.</span>
                </div>
                <!-- .main__content-item_r -->
                <div v-if="showConfirm" class="main__content-item_r">
                  <button
                    class="btn btn-gray btn-bold"
                    @click="confirmChanges()"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div v-if="history.length > 0" class="main__content">
            <div class="main__content-headline">
              <h5>Import history</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div
                v-for="item in history"
                :key="item.id"
                class="main__content-item"
              >
                <div class="main__content-item_l">
                  <span>{{ item.created_at }}</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r main__content-item_r-block">
                  <span
                    ><b>{{ item.created }}</b> added</span
                  >
                  <span
                    ><b>{{ item.updated }}</b> edited</span
                  >
                  <span
                    >by
                    <a :href="'/customers/' + item.user.id">{{
                      item.user.f_name
                    }}</a></span
                  >
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div>
                <paginator
                  :current-page="currentPage"
                  :last-page="lastPage"
                  @changepage="changePage"
                />
              </div>
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
// import $ from "jquery";

export default {
  name: "CRMImportCountComponents",
  components: { CRMLayout, Paginator },
  data() {
    return {
      file: null,
      fileName: null,
      columns: [],
      uploaded: false,
      created: 0,
      edited: 0,
      fileOnServer: null,
      history: [],
      total: 0,
      perPage: 0,
      currentAuthorId: "",
      currentPage: 1,
      lastPage: 1
    };
  },
  created() {
    this.loadColumns();
    this.loadHistory();
  },
  computed: {
    showConfirm() {
      return !!this.file && this.uploaded;
    },
    showConfirmMessage() {
      return !this.uploaded;
    }
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    loadHistory() {
      let ctx = this;

      let options = {
        parameters: []
      };

      options.parameters.push(`page=${ctx.currentPage}`);

      this.$store
        .dispatch("get_import_parts_count_history_request", options)
        .then(response => {
          ctx.history = response.data.data;
          ctx.currentPage = response.data.current_page;
          ctx.lastPage = response.data.last_page;
          ctx.total = response.data.total;
          ctx.perPage = response.data.per_page;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadColumns() {
      let ctx = this;
      this.$store
        .dispatch("get_template_parts_count_columns_request")
        .then(response => {
          ctx.columns = response.data.columns;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    resetFields() {
      this.uploaded = false;
      this.created = 0;
      this.edited = 0;
      this.file = null;
      this.fileOnServer = null;
      this.fileName = null;
      window.$(".template-import").val(null);
    },
    deleteTempFile() {
      let ctx = this;

      this.$store
        .dispatch("delete_import_part_count_file_request", {
          file_path: ctx.fileOnServer
        })
        .then(() => {
          ctx.resetFields();
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    processFile(event) {
      let ctx = this;
      this.file = event.target.files[0];

      let data = new FormData();

      data.append("file", this.file);

      this.$store
        .dispatch("upload_import_part_count_file_request", data)
        .then(response => {
          ctx.uploaded = true;
          ctx.created = response.data.create;
          ctx.edited = response.data.update;
          ctx.fileOnServer = response.data.file_path;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    selectFile() {
      window.$(".template-import").click();
    },
    getTemplate() {
      let ctx = this;
      this.$store
        .dispatch("get_import_parts_count_template_request")
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          let fileName = "template_count.xlsx";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    confirmChanges() {
      let ctx = this;
      this.$store
        .dispatch("confirm_upload_import_part_count_file_request", {
          file_path: ctx.fileOnServer
        })
        .then(() => {
          ctx.resetFields();
          ctx.loadHistory();
          ctx.$root.$emit("modalShow", {
            text: "Parts successfully imported",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  },
  watch: {
    currentPage: function(page) {
      this.currentPage = page;
      this.loadHistory();
    }
  }
};
</script>

<style scoped></style>
