<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>List of CV</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>CVs</h5>
          </div>

          <table v-if="hasCV" class="table__typical table__orders">
            <tbody>
              <tr>
                <th class="pointer">
                  <span>ID</span>
                  <span></span>
                </th>

                <th class="pointer">
                  <span>E-Mail</span>
                </th>

                <th class="pointer">
                  <span>Name</span>
                </th>

                <th class="pointer">
                  <span>Position</span>
                </th>
              </tr>

              <tr v-for="cv in cvs" :key="cv.id" @click="showCV($event, cv)">
                <td>{{ cv.id }}</td>
                <td>{{ cv.email }}</td>
                <td>{{ cv.name }}</td>
                <td>{{ cv.position }}</td>
              </tr>
            </tbody>
          </table>

          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";

export default {
  name: "CRMCVList",
  components: { CRMLayout, Paginator },
  data: () => ({
    cvs: [],
    currentPage: 1,
    lastPage: 1,
    updateList: true
  }),
  mounted() {
    this.loadCV();
  },
  computed: {
    hasCV() {
      return this.cvs.length > 0;
    }
  },
  methods: {
    loadCV() {
      let ctx = this;
      this.$store
        .dispatch("get_cvs_request", { page: this.currentPage })
        .then(response => {
          this.lastPage = response.data.meta.last_page;
          this.currentPage = response.data.meta.current_page;

          this.cvs = response.data.data.map(item => {
            return {
              id: item.id,
              email: item.email,
              name: item.name,
              position:
                !item.position || item.position === "null"
                  ? "Not provided"
                  : item.position,
              created_at: ctx.$moment(this.created_at).format("DD.MM.YYYY")
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadCV();
    },
    showCV(event, cv) {
      if (event.target.localName === "td") {
        this.$router.push({ path: `/cv/${cv.id}` }).catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}

@media screen and (max-width: 767px) {
  .table__orders td:nth-child(2),
  .table__orders th:nth-child(2),
  .table__orders td:last-child,
  .table__orders th:last-child {
    width: 40%;
  }

  .table__typical td:first-child,
  .table__typical th:first-child {
    width: 20%;
    padding-left: 20px;
  }

  .main__c-in {
    padding-right: 20px;
  }
}
</style>
