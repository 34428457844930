<template>
  <layout>
    <template v-slot:header-title>
      <span v-if="canEdit">Edit B2B customer</span>
      <span v-else>B2B Customer page</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in mb-15">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>ID:</span>
                </div>

                <div class="main__content-item_r">
                  <span>
                    <b>{{ customer.id }}</b>
                  </span>
                </div>
              </div>

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Logo:</span>
                </div>

                <div class="main__content-item_r">
                  <div
                    v-if="hasAvatar && !canEdit"
                    class="main__content-avatar"
                  >
                    <img v-if="!canEdit" :alt="fullName" :src="avatar" />
                    <button
                      v-if="canEdit"
                      class="main__content-avatar_close"
                    ></button>
                  </div>
                  <dropzone
                    v-if="canEdit"
                    :id="'customer_avatar'"
                    :default-image="avatar"
                    :options="dropzoneSettings"
                    dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 512x512 up to 15MB)"
                    @vdropzone-files-added="saveLocalAvatar"
                    @vdropzone-removed-file="resetLocalAvatar"
                  />
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Company Name:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-if="canEdit"
                      v-model="customer.f_name"
                      autofocus
                      title="Company Name"
                      type="text"
                    />
                    <span v-else>{{ customer.f_name }}</span>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Shop Name:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-if="canEdit"
                      v-model="customer.l_name"
                      title="Shop Name"
                      type="text"
                    />
                    <span v-else>{{ customer.l_name }}</span>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Account type:</span>
                </div>

                <div class="main__content-item_r">
                  <span v-if="!canEdit">{{ userType }}</span>
                  <div v-else class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentUserType"
                      :options="userTypes"
                      autocomplete="off"
                      label="text"
                      placeholder="Select user type"
                    />
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Website:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input
                      v-if="canEdit"
                      v-model="customer.b2b_data.website"
                      autofocus
                      title="website"
                      type="text"
                    />
                    <a
                      v-else
                      :href="customer.b2b_data.website"
                      target="_blank"
                      >{{ customer.b2b_data.website }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Full address</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Address:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.invoice_address.streetNo"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.invoice_address.streetNo }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Postal Code:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.invoice_address.zip"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.invoice_address.zip }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Country:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.invoice_address.country"
                    :allow-empty="true"
                    :internal-search="true"
                    :label="'title'"
                    :limit="5"
                    :options="countries"
                    :placeholder="'Select country'"
                    :showNoResults="false"
                    :track-by="'id'"
                  />
                </div>
                <span v-else>
                  <span v-if="customer.invoice_address.country">{{
                    customer.invoice_address.country.title
                  }}</span>
                </span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Citу:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.invoice_address.city"
                    :allow-empty="true"
                    :internal-search="false"
                    :label="'title'"
                    :limit="5"
                    :loading="isLoadingInvoiceAddressCities"
                    :noOptions="'Start typing'"
                    :options="cities"
                    :placeholder="manualInvoiceCity || 'Select city'"
                    :preserveSearch="true"
                    :showNoResults="false"
                    :track-by="'id'"
                    @search-change="changeInvoiceCity"
                  />
                </div>
                <span v-else>
                  <span v-if="customer.invoice_address.city">{{
                    customer.invoice_address.city.title
                  }}</span>
                </span>
              </div>
            </div>

            <hr class="form_hr" />

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Delivery address:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.delivery_address.streetNo"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.delivery_address.streetNo }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Postal Code:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.delivery_address.zip"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.delivery_address.zip }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Country:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.delivery_address.country"
                    :allow-empty="true"
                    :internal-search="true"
                    :label="'title'"
                    :limit="5"
                    :options="countries"
                    :placeholder="'Select country'"
                    :showNoResults="false"
                    :track-by="'id'"
                  />
                </div>
                <span v-else>
                  <span v-if="customer.invoice_address.country">{{
                    customer.invoice_address.country.title
                  }}</span>
                </span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Citу:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <multiselect
                    v-model="customer.delivery_address.city"
                    :allow-empty="true"
                    :internal-search="false"
                    :label="'title'"
                    :limit="5"
                    :loading="isLoadingDeliveryAddressCities"
                    :noOptions="'Start typing'"
                    :options="cities"
                    :placeholder="manualDeliveryCity || 'Select city'"
                    :preserveSearch="true"
                    :showNoResults="false"
                    :track-by="'id'"
                    @search-change="changeDeliveryCity"
                  />
                </div>
                <span v-else>
                  <span v-if="customer.invoice_address.city">{{
                    customer.invoice_address.city.title
                  }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Contacts</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Main contact telephone:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.mobile_phone"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.mobile_phone }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Alternative contact telephone:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.b2b_data.alt_phone"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.b2b_data.alt_phone }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Main contact email address:</span>
              </div>

              <div class="main__content-item_r  flex">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input v-model="customer.email" title="Height" type="text" />
                </div>
                <span v-else>{{ customer.email }}</span>

                <span v-if="customer.verify_email" class="fl">Confirmed</span>
                <span v-else class="fl"
                  >Not confirmed
                  <a href="#" @click.prevent="confirmUser">Confirm?</a></span
                >
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Alternative contact email address:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__xl">
                  <input
                    v-model="customer.b2b_data.alt_email"
                    title="Height"
                    type="text"
                  />
                </div>
                <span v-else>{{ customer.b2b_data.alt_email }}</span>
              </div>
            </div>

            <hr class="form_hr" />

            <div v-for="(contact, index) in customer.contacts" :key="index">
              <h3 class="text-center mb-30">
                <span v-if="index === 0">Main</span
                ><span v-else>Alternative</span> contact person
                <button
                  v-if="canEdit"
                  class="btn btn-white btn-bold"
                  @click="removeContact(index)"
                >
                  Delete
                </button>
              </h3>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Full Name:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <input
                      v-model="contact.full_name"
                      title="Height"
                      type="text"
                    />
                  </div>
                  <span v-else>{{ contact.full_name }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Position:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <input
                      v-model="contact.position"
                      title="Height"
                      type="text"
                    />
                  </div>
                  <span v-else>{{ contact.position }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Email:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <input v-model="contact.email" title="Height" type="text" />
                  </div>
                  <span v-else>{{ contact.email }}</span>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Phone:</span>
                </div>

                <div class="main__content-item_r">
                  <div v-if="canEdit" class="inp-group inp-group__xl">
                    <input v-model="contact.phone" title="Height" type="text" />
                  </div>
                  <span v-else>{{ contact.phone }}</span>
                </div>
              </div>

              <hr v-if="canEdit" class="form_hr" />
            </div>

            <div class="text-center">
              <button
                v-if="canEdit"
                class="btn btn-white btn-bold"
                @click="addContact"
              >
                Add contact
              </button>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>Other</h5>
          </div>

          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Payment Terms:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__textarea">
                  <textarea
                    v-model="customer.b2b_data.payment_terms"
                    type="text"
                  ></textarea>
                </div>
                <span v-else>{{ customer.b2b_data.payment_terms }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Delivery Terms:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__textarea">
                  <textarea
                    v-model="customer.b2b_data.delivery_terms"
                    type="text"
                  ></textarea>
                </div>
                <span v-else>{{ customer.b2b_data.delivery_terms }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Bank Account (IBAN/Swift):</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__textarea">
                  <textarea
                    v-model="customer.b2b_data.bank_account"
                    type="text"
                  ></textarea>
                </div>
                <span v-else>{{ customer.b2b_data.bank_account }}</span>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Notes:</span>
              </div>

              <div class="main__content-item_r">
                <div v-if="canEdit" class="inp-group inp-group__textarea">
                  <textarea
                    v-model="customer.b2b_data.notes"
                    type="text"
                  ></textarea>
                </div>
                <span v-else>{{ customer.b2b_data.notes }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-headline">
            <h5>
              Orders <span v-if="orders">({{ orders.length }})</span>
            </h5>
          </div>

          <div v-if="!orders" class="main__content-in">
            Loading...
          </div>

          <div v-else class="main__content-in">
            <div v-if="orders.length === 0">
              Empty
            </div>
            <div v-else>
              <table class="table__typical table__orders">
                <thead>
                  <tr>
                    <th>PO #</th>
                    <th>Created by</th>
                    <th>Date<br />created</th>
                    <th>Date<br />produced</th>
                    <th>Date<br />delivered</th>
                    <th>Units #</th>
                    <th>Value, €</th>
                    <th>Status</th>
                    <th>Details</th>
                    <th>Product ordered</th>
                    <th>Test bikes<br />(Units #)</th>
                    <th>Test bikes<br />(What models)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in orders"
                    :key="order.id"
                    @click="showOrder($event, order)"
                  >
                    <td class="border-bottom">
                      <router-link
                        :to="`/orders/` + order.id"
                        class="underline"
                      >
                        {{ order.id }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="order.creator"
                        >{{ order.creator.f_name }}
                        {{ order.creator.l_name }}
                      </template>
                    </td>
                    <td>{{ dateStringFormat(order.created_at) }}</td>
                    <td>
                      {{ dateStringFormat(getDateByStatus(order, "Produced")) }}
                    </td>
                    <td>
                      {{
                        dateStringFormat(getDateByStatus(order, "Delivered"))
                      }}
                    </td>
                    <td>{{ order.quantity }}</td>
                    <td>{{ $crConverter(order.total_price / 100) }}</td>
                    <td>
                      <b>{{ order.status.status }}</b>
                    </td>
                    <td>
                      <a
                        v-if="canViewDocs()"
                        class="pdf_link"
                        @click.prevent="downloadPdf(order)"
                      >
                        <span>pdf</span>
                      </a>
                      <span v-else>No access</span>
                    </td>
                    <td>
                      <template v-if="order.basket && order.basket.items">
                        <div
                          :key="index"
                          v-for="(model, index) in bikesModels(order.basket.items)"
                        >
                          {{ model }}
                        </div>
                      </template>
                    </td>
                    <td>
                      <template v-if="order.basket && order.basket.items">
                        {{ testingBikesCount(order.basket.items) }}
                      </template>
                    </td>
                    <td>
                      <template v-if="order.basket && order.basket.items">
                        <div
                          v-for="(model, index) in testingBikesModels(
                            order.basket.items
                          )"
                          :key="index"
                        >
                          {{ model }}
                        </div>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{ sumOrdersDataBy("quantity") }}</th>
                    <th>
                      {{ $crConverter(sumOrdersDataBy("total_price") / 100) }}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="mt-15 d-flex space-between orders__actions">
              <div>
                <router-link class="underline" to="/orders/create">
                  Create new order
                </router-link>
              </div>
              <div v-if="orders.length > 0">
                <span class="underline cursor-pointer" @click="exportOrders"
                  >Export</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="main__content-btns">
          <button v-if="canEdit" class="btn btn-big btn-fw" @click="update">
            <span><i>Save user info</i></span>
          </button>
          <button
            v-if="canEdit"
            class="btn btn-big btn-fw"
            @click="deleteCustomer()"
          >
            <span><i>Delete</i></span>
          </button>
          <button class="btn btn-big btn-fw" @click="goBack()">
            <span><i>Cancel</i></span>
          </button>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import Dropzone from "./forms/inputs/Dropzone";
import { STORAGE_ENDPOINT } from "@/utils/api";
import { Multiselect } from "vue-multiselect";
import _ from "lodash";

export default {
  name: "CRMCustomerB2bPage",
  components: {
    layout: CRMLayout,
    vSelect,
    Dropzone,
    Multiselect
  },
  data() {
    return {
      orders: null,
      customer: {
        id: "",
        avatar_path: "",
        f_name: "",
        l_name: "",
        email: "",
        mobile_phone: "",
        user_type_id: null,
        verify_email: null,
        invoice_address: {
          city: null,
          country: null,
          flat: null,
          streetNo: "",
          zip: ""
        },
        delivery_address: {
          city: null,
          country: null,
          flat: null,
          streetNo: "",
          zip: ""
        },
        b2b_data: {
          website: null,
          alt_phone: null,
          alt_email: null,
          payment_terms: null,
          delivery_terms: null,
          bank_account: null,
          notes: null
        },
        contacts: [],
        last_login_timestamp: "Not login yet."
      },
      currentUserType: {},
      can_edit: true,
      userTypes: [],
      local_avatar: null,
      cities: [],
      countries: [],
      manualInvoiceCity: null,
      manualDeliveryCity: null,
      isLoadingInvoiceAddressCities: false,
      isLoadingDeliveryAddressCities: false,
      user: JSON.parse(localStorage.getItem("user"))
    };
  },
  created() {
    this.loadUserTypes();
  },
  methods: {
    testingBikesCount(items) {
      let count = 0;
      items.map(function(item) {
        if (item.is_testing) {
          count += item.qty;
        }
      });
      return count;
    },
    bikesModels(items) {
      let models = [];
      items.map(function(item) {
        models.push(item.build.model.model);
      });
      return _.uniq(models);
    },
    testingBikesModels(items) {
      let models = [];
      items.map(function(item) {
        if (item.is_testing) {
          models.push(item.build.model.model);
        }
      });
      return _.uniq(models);
    },
    exportOrders() {
      this.$dialog
        .confirm(`The report will be sent to ${this.$store.getters.user.email}.`)
        .then(() => {
          this.$store
            .dispatch("orderList/exportUserOrdersToExcel", this.customer.id)
            .then(() => {
              this.$root.$emit("modalShow", {
                text:
                  "As soon as the report is ready, you will receive it by email",
                type: "info"
              });
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });
            });
        })
        .catch(() => {});
    },
    canViewDocs() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 5) {
          return permission.view === 1;
        }
      }
    },
    downloadPdf(order) {
      let ctx = this;
      this.$store
        .dispatch("download_order_pdf_request", order.id)
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          let fileName = `pro-forma-${order.id}`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    showOrder(event, order) {
      if (event.target.localName === "td") {
        this.$router.push(`/orders/${order.id}`).catch(() => {});
      }
    },
    sumOrdersDataBy(field) {
      if (this.orders.length > 0) {
        return _.sumBy(this.orders, field);
      }
      return 0;
    },
    dateStringFormat(date) {
      if (date) {
        return this.$moment(date).format("DD.MM.YYYY");
      }
      return "";
    },
    getDateByStatus(order, status) {
      let exists = _.find(order.order_status_history, [
        "rel_status.status",
        status
      ]);
      if (exists) {
        return exists.created_at;
      }
      return null;
    },
    confirmUser() {
      this.$dialog
        .confirm("Are you sure you want to verify email of this customer?")
        .then(() => {
          this.verifyEmail();
        })
        .catch(() => {});
    },
    verifyEmail() {
      this.$store
        .dispatch("user/verify", this.customer.id)
        .then(() => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: "User verified successfully"
          });
          this.loadUserTypes();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    addContact() {
      this.customer.contacts.push({
        full_name: "",
        position: "",
        email: "",
        phone: ""
      });
    },
    removeContact(contact_index) {
      this.customer.contacts = _.reject(this.customer.contacts, function(o, i) {
        return i === contact_index;
      });
    },
    setCanEdit() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 2) {
          if (permission.edit === 1) {
            this.can_edit = true;
          }
          this.can_edit = false;
        }
      }
    },
    changeInvoiceCity(searchQuery) {
      if (searchQuery) {
        this.isLoadingInvoiceAddressCities = true;
        this.customer.invoice_address.city = null;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            this.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            this.isLoadingInvoiceAddressCities = false;
          })
          .catch(() => {
            this.isLoadingInvoiceAddressCities = false;
          });
      }

      if (!(!this.customer.invoice_address.city && !searchQuery)) {
        this.manualInvoiceCity = searchQuery;
      }
    },
    changeDeliveryCity(searchQuery) {
      if (searchQuery) {
        this.isLoadingDeliveryAddressCities = true;
        this.customer.delivery_address.city = null;
        this.$store
          .dispatch("get_cities_request", searchQuery)
          .then(response => {
            this.cities = response.data.map(city => {
              return {
                id: city.id,
                title: city.town_name
              };
            });
            this.isLoadingDeliveryAddressCities = false;
          })
          .catch(() => {
            this.isLoadingDeliveryAddressCities = false;
          });
      }

      if (!(!this.customer.delivery_address.city && !searchQuery)) {
        this.manualDeliveryCity = searchQuery;
      }
    },
    loadCountries() {
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          this.countries = response.data.map(country => {
            return {
              id: country.id,
              title: country.country_name
            };
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    resetLocalAvatar() {
      this.local_avatar = null;
    },
    saveLocalAvatar(file) {
      this.local_avatar = file[0];
    },
    loadCustomerData() {
      let self = this;
      this.$store
        .dispatch("get_customer", this.$route.params.id)
        .then(response => {
          for (let key of Object.keys(response.data)) {
            if (
              key === "invoice_address" ||
              key === "delivery_address" ||
              key === "contacts" ||
              key === "b2b_data"
            ) {
              continue;
            }
            // eslint-disable-next-line
            if (this.customer.hasOwnProperty(key)) {
              this.customer[key] = response.data[key];
            }
          }

          if (response.data.contacts) {
            this.customer.contacts = response.data.contacts;
          }
          if (response.data.b2b_data) {
            this.customer.b2b_data = response.data.b2b_data;
          }

          if (response.data.invoice_address) {
            this.customer.invoice_address.zip =
              response.data.invoice_address.zip;
            this.customer.invoice_address.flat =
              response.data.invoice_address.flat;
            this.customer.invoice_address.streetNo =
              response.data.invoice_address.streetNo;
            if (response.data.invoice_address.city) {
              this.customer.invoice_address.city = {
                id: response.data.invoice_address.city.id,
                title: response.data.invoice_address.city.town_name
              };
            }
            if (response.data.invoice_address.country) {
              this.customer.invoice_address.country = {
                id: response.data.invoice_address.country.id,
                title: response.data.invoice_address.country.country_name
              };
            }
          }
          if (response.data.delivery_address) {
            this.customer.delivery_address.zip =
              response.data.delivery_address.zip;
            this.customer.delivery_address.flat =
              response.data.delivery_address.flat;
            this.customer.delivery_address.streetNo =
              response.data.delivery_address.streetNo;
            if (response.data.delivery_address.city) {
              this.customer.delivery_address.city = {
                id: response.data.delivery_address.city.id,
                title: response.data.delivery_address.city.town_name
              };
            }
            if (response.data.delivery_address.country) {
              this.customer.delivery_address.country = {
                id: response.data.delivery_address.country.id,
                title: response.data.delivery_address.country.country_name
              };
            }
          }
          self.customer.verify_email = response.data["email_verified_at"]
            ? response.data["email_verified_at"]
            : 0;

          if (self.customer.user_type_id) {
            for (let userType of self.userTypes) {
              if (userType.code === self.customer.user_type_id) {
                self.currentUserType = userType;
              }
            }
          }

          this.loadCustomerOrders();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    loadCustomerOrders() {
      this.$store
        .dispatch("get_customer_orders_request", this.customer.id)
        .then(response => {
          this.orders = response.data;
        })
        .catch(error => {
          this.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    loadUserTypes() {
      let ctx = this;
      this.$store
        .dispatch("get_user_types")
        .then(resp => {
          let b2bType = _.find(this.$store.getters.userBusinessTypes, {
            name: "b2b"
          });
          ctx.userTypes = _.filter(resp.data, {
            business_type: b2bType.id
          }).map(type => {
            return {
              code: type.id,
              text: type.type[0].toUpperCase() + type.type.slice(1)
            };
          });
          this.loadCustomerData();
          this.loadCountries();
          this.setCanEdit();
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    async update() {
      let self = this;
      let updateData = self.formData;

      this.$store
        .dispatch("update_customer_b2b_request", {
          data: updateData,
          id: self.$route.params.id
        })
        .then(response => {
          if (self.local_avatar) {
            let avatarData = new FormData();
            avatarData.append("avatar_path", self.local_avatar);
            self.$store
              .dispatch("update_user_avatar", {
                avatar: avatarData,
                id: response.data.data.id
              })
              .then(resp => {
                self.customer.avatar_path = resp.data.data.avatar_path;
                self.local_avatar = null;

                this.$root.$emit("modalShow", {
                  type: "info",
                  text: "Successfully updated"
                });
                this.$router.push("/customers").catch(() => {});
              })
              .catch(err => {
                this.$root.$emit("modalShow", { text: err, type: "error" });
              });
          } else {
            this.$root.$emit("modalShow", {
              type: "info",
              text: "Successfully updated"
            });
            this.$router.push("/customers").catch(() => {});
          }

          if (response.data.data.id === self.$store.getters.user.id) {
            let profile = response.data.data;

            localStorage.setItem("user", JSON.stringify(profile));

            self.$store.commit("auth_success", { token: false, profile });
          }
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    goBack() {
      window.history.length
        ? this.$router.go(-1)
        : this.$router.push({ name: "CRMManageCustomer" }).catch(() => {});
    },
    deleteCustomer() {
      this.$dialog
        .confirm("Are you sure you want to delete this customer")
        .then(() => {
          this.$store
            .dispatch("delete_user_request", this.customer.id)
            .then(response => {
              this.$router.push({ name: "CRMCustomerList" }).catch(() => {});
              this.$root.$emit("modalShow", {
                type: "info",
                text: response.data.message
              });
            })
            .catch(error => {
              this.$root.$emit("modalShow", { type: "error", text: error });
            });
        })
        .catch(() => {});
    }
  },
  computed: {
    formData() {
      let data = this.customer;

      data.user_type_id = this.currentUserType.code;

      if (data.invoice_address.city) {
        data.invoice_address.city_id = data.invoice_address.city.id;
      } else {
        data.invoice_address.city_name = this.manualInvoiceCity;
      }
      if (data.invoice_address.country) {
        data.invoice_address.country_id = data.invoice_address.country.id;
      }
      if (data.delivery_address.city) {
        data.delivery_address.city_id = data.delivery_address.city.id;
      } else {
        data.delivery_address.city_name = this.manualDeliveryCity;
      }
      if (data.delivery_address.country) {
        data.delivery_address.country_id = data.delivery_address.country.id;
      }

      return data;
    },
    canEdit() {
      //return false;
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 2) {
          return permission.edit === 1;
        }
      }
      return false;
    },
    fullName() {
      return this.customer.l_name + " " + this.customer.f_name;
    },
    avatar() {
      return this.customer.avatar_path
        ? STORAGE_ENDPOINT + this.customer.avatar_path
        : null;
    },
    hasAvatar() {
      return !!this.customer.avatar_path;
    },
    dropzoneSettings() {
      return {
        url: `rand`,
        uploadMultiple: false,
        paramName: "avatar_path",
        autoProcessQueue: false,
        addRemoveLinks: true
      };
    },
    userType() {
      let self = this;
      let types = this.$store.getters.userTypes;
      if (types.length > 0) {
        types = types.filter(type => {
          return type.id === self.customer.user_type_id;
        });
        if (types[0]) {
          return types[0].type[0].toUpperCase() + types[0].type.slice(1);
        }
      }
      return "Wait..";
    }
  },
  watch: {
    //
  }
};
</script>

<style scoped>
.form_hr {
  left: 0;
  max-width: 700px;
}

.table__orders td,
.table__orders th {
  width: auto !important;
}

.table__orders th,
.table__orders td {
  text-align: center;
}

.table__orders th:first-child,
.table__orders td:first-child {
  text-align: left;
}
</style>
